import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FormErrorMessageService {

    private datePipe = new DatePipe('en-US');

    constructor() {}

    getErrorMessages(errors: any): string[] | null {
        
        let errorMessages = [];
        if (errors === null) {
          return null;
        }
        if (errors.required) {
            errorMessages.push("This field is required.");
        }
        if (errors.number) {
            errorMessages.push("This field must be a number.");
        }
        if (errors.maxlength) {
            errorMessages.push("This field should not exceed " + errors.maxlength.requiredLength + " characters.");
        }
        if (errors.minlength) {
            errorMessages.push("This field should contain at least " + errors.minlength.requiredLength + " characters.");
        }

        if (errors.outOfRange && !errors.outOfRange.valid) {
            errorMessages.push(errors.outOfRange.errorMessage)
        }

        if (errors.mindate) {
            if (!errors.mindate.isDate) {
                errorMessages.push("This is not a valid date.");
            } else {
                errorMessages.push(`The date can not be before ${this.datePipe.transform(errors.mindate.minDate, 'mediumDate')}.`);
            }
        }
        if (errors.maxdate) {
            if (!errors.maxdate.isDate) {
                errorMessages.push("This is not a valid date.");
            } else {
                errorMessages.push(`The date can not be after ${this.datePipe.transform(errors.maxdate.maxDate, 'mediumDate')}.`);
            }
        }

        if (errors.dateRange) {
            errorMessages.push(errors.dateRange.errorMessage);
        }

        if (errors.travelerName) {
            errorMessages.push(errors.travelerName.errorMessage);
        }
        return errorMessages;
    }

}
