import { IApiDeserializable } from '../../core/models/api-deserializable';

export class Delegator implements IApiDeserializable {
    DelegateAgentId: number;

    DelegatorFirstName: string;

    DelegatorLastName: string;

    // Optional property for select/unselect
    selected?: boolean;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
