import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SupportTicketSubCategoryType } from '../enums/support-ticket-sub-category-type.enum';
import { HelpSpotDestinationTypes } from '../enums/help-spot-destination-types.enum';

export interface TicketDto {
    Notes: any,
    SupportTicketSubCategoryTypeId: SupportTicketSubCategoryType,
    HelpSpotDestination: HelpSpotDestinationTypes,
    UserAgent: any
}

@Injectable({ providedIn: 'root' })
export class RequestSupportApiService {
    apiUrl = '/api';

    constructor(private http: HttpClient) {

    }

    /**
    * Make support request
    */
    sendSupportRequest(supportRequest: any): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/supportticket/send`, supportRequest);
    }

    ceateSupportTicket(tsId: number, ticketDto: TicketDto): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/supportTicket/travelService/${tsId}`, ticketDto);
    }

}
