import { Directive, OnInit, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgbTooltip, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { AdxFormControl } from '../form-controls/adx-form-control';

/**
 * Displays validation errors in a tooltip.
 * */
@Directive({
    selector: '[adxValidationTooltip]',
    hostDirectives: [
        {
            directive: NgbTooltip,
            inputs: [],
            outputs: []
        }]
})
export class ValidationTooltipDirective implements OnInit {
    @Input() tooltipPlacement: string = 'auto';

    constructor(private tooltip: NgbTooltip, private formControl: NgControl) { }

    ngOnInit(): void {
        this.formControl.valueChanges.subscribe(() => {
            this.tooltip.ngbTooltip = null;

            if (!this.formControl.valid && !(this.formControl.pristine && this.formControl.untouched) && !this.formControl.disabled) {
                if (this.formControl.control) {
                    let adxControl = this.formControl.control as AdxFormControl;
                    if (adxControl && adxControl.businessRules && adxControl.businessRules.CustomErrorMessage) {

                        this.tooltip.ngbTooltip = adxControl.businessRules.CustomErrorMessage;
                    }
                    else {
                        this.tooltip.ngbTooltip = this.getErrorMessages(this.formControl.errors).join(' ');
                    }
                }
                else {
                    this.tooltip.ngbTooltip = this.getErrorMessages(this.formControl.errors).join(' ');
                }

                this.tooltip.placement = this.tooltipPlacement;
                this.tooltip.autoClose = false;

                if (!this.tooltip.isOpen()) {
                    this.tooltip.toggle();
                }
            }
        });
    }

    getErrorMessages(errors: any): string[] {
        const messages = [];
        const datePipe = new DatePipe('en-US');

        if (errors === null) {
            return messages;
        }

        if (errors.required) {
            messages.push("This field is required.");
        }
        if (errors.year) {
            messages.push(errors.year.errorMessage);
        }
        if (errors.number) {
            messages.push("This field must be a number.");
        }
        if(errors.pattern){
            messages.push("Invalid format");
        }
        if (errors.maxlength) {
            messages.push("This field should not exceed " + errors.maxlength.requiredLength + " characters.");
        }
        if (errors.minlength) {
            messages.push("This field should contain at least " + errors.minlength.requiredLength + " characters.");
        }

        if (errors.outOfRange && !errors.outOfRange.valid) {
            messages.push(errors.outOfRange.errorMessage)
        }

        if (errors.max) {
            messages.push(`Maximum allowable amount is ${errors.max.max}.`)
        }

        if (errors.min) {
            messages.push(`Minimum allowable amount is ${errors.min.min}.`)
        }

        if (errors.mindate) {
            if (!errors.mindate.isDate) {
                messages.push("This is not a valid date.");
            } else {
                messages.push(`The date can not be before ${datePipe.transform(errors.mindate.minDate, 'mediumDate')}.`);
            }
        }
        if (errors.maxdate) {
            if (!errors.maxdate.isDate) {
                messages.push("This is not a valid date.");
            } else {
                messages.push(`The date can not be after ${datePipe.transform(errors.maxdate.maxDate, 'mediumDate')}.`);
            }
        }

        if (errors.creditcard) {
            messages.push("This is not a valid credit card number.");
        }

        if (errors.verificationcode) {
            messages.push("This code does not match the credit card CVV requirements.");
        }

        if (errors.dateRange) {
            messages.push(errors.dateRange.errorMessage);
        }

        if (errors.travelerName) {
            messages.push(errors.travelerName.errorMessage);
        }

        if (errors.travelerDateOfBirth) {
            messages.push(errors.travelerDateOfBirth.errorMessage);
        }

        if (errors.externalServicePayments) {
            messages.push(errors.externalServicePayments.errorMessage);
        }

        if (errors.incorrectValueType) {
            messages.push(errors.incorrectValueType.errorMessage);
        }
        if(errors?.custom){
            messages.push(errors.custom.errorMessage);
        }

        return messages;
    }
}
