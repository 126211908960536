import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LoginApiService } from '../../login/services/login-api.service';
import {PathLocationStrategy} from "@angular/common";

/**
 *
 * */
@Injectable({ providedIn: 'root' })
export class AuthGuardService  {
  constructor(private router: Router, private loginApiService: LoginApiService,private pathLocationStrategy: PathLocationStrategy) {  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.loginApiService.getLoggedInUser().pipe(
      //temporary solution for /v1/ dependent pages
      switchMap((): Observable<boolean | UrlTree> => {
        const patterns = [
          '/planningfee?agentId',
          '/planningfee/send?quoteId',
          '/cruisesearchresults?csc',
          '/airsearchresults?asc',
          '/hotel-search?hsc',
          '/office-management',
          '/notifications',
        ];

        const path = this.pathLocationStrategy.path();

        const matchesPattern = patterns.some(pattern => path.startsWith(pattern));
        if (matchesPattern) {
          window.location.href = `/v1${path}`;
        }
        return of(true);
      }),
      catchError(err => {
        let tree = this.router.parseUrl('/login');

        return of(tree);
      })
    );
  }
}
