import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Provides access to the support API, allowing for submission of
 * agent issues and logging of client-side errors.
 * */
@Injectable({ providedIn: 'root' })
export class SupportApiService {
    apiUrl = '/api';

    constructor(private http: HttpClient) {
         
    }

    logJsError(error: any): Observable<any> {
        // Not an API call, this is a call to the Web project.
        return this.http.post<any>(`/v1/logging/log-error`, error);
    }

    raiseJsError(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/errors/raise?type=JsTravelEdgeException`);
    }
}
