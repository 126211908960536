import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoggedInUser } from '../models/logged-in-user.model';
import { LoginResponse } from '../models/login-response.model';
import { TemporaryBundleContainerService } from '../../trip-services-common/services/TemporaryBundleContainerService';

/**
 * Provides the ADX Login API.
 * */
@Injectable({ providedIn: 'root' })
export class LoginApiService {
    apiUrl = '/api';

    constructor(private http: HttpClient,  private temporaryBundleContainerService: TemporaryBundleContainerService) { }

    login(params: { email: string, password: string }): Observable<LoginResponse> {
        this.temporaryBundleContainerService.clearTemporaryBundle();
        return this.http.post<LoginResponse>(`${this.apiUrl}/auth`, params);
    }

    logout(): Observable<any> {
        this.temporaryBundleContainerService.clearTemporaryBundle();
        return this.http.delete<any>(`${this.apiUrl}/auth`);
    }

    depersonate(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/auth/depersonate`);
    }

    getReportUrl(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/auth/report-sso`);
    }

    getEnsembleUrl(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/auth/ensemble-sso`);
    }

    getLoggedInUser(): Observable<any> {
        // See LoggedInUser.model.ts
        return this.http.get<any>(`${this.apiUrl}/auth/me`);
    }

    acceptTermsAndConditions(): Observable<any> {
        return this.http.post(`${this.apiUrl}/users/AcceptTaC`, { params: null });
    }

    resetPassword(params: { email: string }): Observable<any> {
        return this.http.post(`${this.apiUrl}/users/resetpassword`, params);
    }

    setNewPassword(params: { Token: string, NewPassword: string }): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/password`, params);
    }

    validateResetPasswordToken(token: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/users/token-valid`, { params: { resetToken: token } });
    }
}
