import { IApiDeserializable } from '../../core/models/api-deserializable';

/* Agent's Address info.*/

export class Address {
    Address1: string;
    Address2: string;
    City: string;
    Subdivision?: {
        SubdivisionId: number
    };
    Country: {
        CountryId: number
    };
    Zip: string;
}
/* Agent's business info.*/
export class AgentBusinessProfile implements IApiDeserializable {
    ProfileId: number;
    BusinessName: string;
    CompanyName: string;
    Email: string;
    Website: string;
    Biography: string;
    Phone: string;
    Mobile: string;
    Fax: string;
    Address?: Address;
    UseVirtuosoProfileImage?: boolean;
    RegulatoryInfortation: string;
    FacebookUrl: string;
    TwitterUrl: string;
    LinkedInUrl: string;
    InstagramUrl: string;
    DisplayInstagram: boolean;
    DisplayTwitter: boolean;
    DisplayLinkedIn: boolean;
    DisplayFacebook: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
