import { Component } from '@angular/core';

/**
 * Displayed when we encounter front-end errors.
 */
@Component({
    selector: 'adx-error-header',
    templateUrl: './error-header.component.html',
    styleUrls: ['./error-header.component.scss']
})
export class ErrorHeaderComponent {

}