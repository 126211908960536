<adx-panel [iconClass]="'fas fa-user'">
    <div panel-header>
        Please Sign In
    </div>
    <div panel-content>
        <div class="login-container">
            <adx-login (login)="onLogin()"></adx-login>
        </div>
    </div>
</adx-panel>
