import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ExternalTravelServiceTypes, TravelServiceTypes} from '../../itinerary/enums/travel-service-types.enum';
import {BundleAddServiceModel} from '../../itinerary/models/bundle-add-service-dto.model';
import {BundleCreateDto} from '../models/bundle-create-dto.model';
import {ItineraryApiService} from '../../itinerary/services/itinerary-api.service';
import {QuoteBundleDto} from '../../itinerary/models/quote-bundle-dto.model';
import {BundlePatchDto} from '../models/bundle-patch.model';
import {BundleQuoteApiService} from '../../itinerary/services/bundle-quote-api.service';
import {TravelServiceBundleTypes} from '../enums/travel-service-bundle-types.enum';

// Prefix used for local storage keys to identify temporary bundles
const prefix: string = 'bundle-temp-';

/**
 * Service for adding services to a bundle.
 * Provides methods to manage the temporary bundle and track the progress of bundle creation.
 */
@Injectable({
  providedIn: 'root'
})
export class TemporaryBundleContainerService {
  constructor(private bundlesApi: BundleQuoteApiService) {
  }

  // Subject representing the current bundle UUID
  private bundleUuidSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // Observable that emits the current bundle UUID
  public bundleUuid$ = this.bundleUuidSubject.asObservable();

  /**
   * Adds a temporary bundle identifier to the service bundle model.
   * @param travelServiceType - The type of travel service for the bundle.
   * @param bundleCreateDto - The bundle create DTO or patch DTO to add to the bundle model.
   * @param quoteId - The ID of the quote associated with the bundle.
   * @param bundleId - Optional: The ID of the bundle to add the service to if the bundle already exists.
   * @returns The updated bundle model with the added service details.
   */
  addTemporaryBundleIdentifier(travelServiceType: TravelServiceTypes | ExternalTravelServiceTypes,
                               bundleCreateDto: BundleCreateDto | BundlePatchDto,
                               quoteId: number,
                               bundleId?: number): BundleAddServiceModel {
    return {
      bundleCreateDto,
      Uuid: this.createUuid(),
      quoteId,
      bundleId,
      isBundleFlowInProgress: true,
      TravelServiceType: travelServiceType,
      isServiceAdded: false,
    };
  }

  /**
   * Generates a random UUID.
   * @private
   * @returns A randomly generated UUID.
   */
  private createUuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /**
   * Returns a filter with the bundle properties needed to filter
   * @param filter any kind of search filter
   * @returns the filter with the properties needed to filter by bundles
   */
  addBundleFilter(filter: any): any {
    return {
      ...filter,
      TravelServiceBundleTypeId: [TravelServiceBundleTypes.CanBeBundled, TravelServiceBundleTypes.MustBeBundled],
      ApplyFiltering: true
    }
  }

  /**
   * Retrieves a temporary bundle by its UUID.
   * @param bundleUuid - The UUID of the temporary bundle to retrieve.
   * @returns The temporary bundle if found, otherwise null.
   */
  getBundle(bundleUuid: string): BundleAddServiceModel | null {
    const BundleAddServiceModel: BundleAddServiceModel = JSON.parse(localStorage.getItem(prefix + bundleUuid));
    return !!BundleAddServiceModel ? BundleAddServiceModel : null;
  }

  /**
   * Clears a specific temporary bundle or all temporary bundles if no UUID is provided.
   * @param bundleUuid - Optional: The UUID of the temporary bundle to clear.
   */
  clearTemporaryBundle(bundleUuid: string = ''): void {

    const clearAllTemporaryBundles = () => {
      for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        if (key && key.startsWith(prefix)) {
          localStorage.removeItem(key);
        }
      }
    }
    bundleUuid !== '' ? localStorage.removeItem(prefix + bundleUuid) : clearAllTemporaryBundles()
  }

  /**
   * Marks a bundle as being in the process of creation.
   * @param bundleUuid - The UUID of the bundle to mark as in progress.
   */
  startBundleCreation(bundleUuid: string): void {
    const BundleAddServiceModel: BundleAddServiceModel = this.getBundle(bundleUuid);
    if (!!BundleAddServiceModel) {
      BundleAddServiceModel.isBundleFlowInProgress = true;
      localStorage.setItem(prefix + bundleUuid, JSON.stringify(BundleAddServiceModel));
    }
  }

  /**
   * Checks if a bundle creation is in progress for the given UUID.
   * @param bundleUuid - The UUID of the bundle to check.
   * @returns True if the bundle creation is in progress, otherwise false.
   */
  isBundleCreationInProgress(bundleUuid: string): boolean {
    const BundleAddServiceModel: BundleAddServiceModel = this.getBundle(bundleUuid);
    if (BundleAddServiceModel) {
      return BundleAddServiceModel.isBundleFlowInProgress ?? false;
    }
    return false;
  }

  /**
   * Marks a service as added in the temporary bundle model.
   * @param bundleUuid - The UUID of the bundle containing the service to mark as added.
   */
  setServiceAdded(bundleUuid: string): void {
    const BundleAddServiceModel: BundleAddServiceModel = this.getBundle(bundleUuid);
    if (BundleAddServiceModel) {
      BundleAddServiceModel.isServiceAdded = true;
      localStorage.setItem(prefix + bundleUuid, JSON.stringify(BundleAddServiceModel));
    }
  }

  /**
   * Checks if a service is added to the bundle with the given UUID.
   * @param bundleUuid - The UUID of the bundle to check.
   * @returns True if the service is added, otherwise false.
   */
  isServiceAdded(bundleUuid: string): boolean {
    const BundleAddServiceModel: BundleAddServiceModel = this.getBundle(bundleUuid);
    return !!BundleAddServiceModel.isServiceAdded ? BundleAddServiceModel.isServiceAdded : false;
  }

  /**
   * Adds a service to the temporary bundle.
   * @param serviceType - The type of travel service for the bundle.
   * @param bundleCreateDto - The bundle create DTO or patch DTO to add to the bundle model.
   * @param quoteId - The ID of the quote associated with the bundle.
   * @param bundleId - Optional: The ID of the bundle to add the service to if the bundle already exists.
   */
  addService(serviceType: TravelServiceTypes | ExternalTravelServiceTypes, bundleCreateDto: BundleCreateDto | BundlePatchDto, quoteId: number, bundleId?: number): BundleAddServiceModel {
    const bundleAddServiceDto: BundleAddServiceModel = this.addTemporaryBundleIdentifier(serviceType, bundleCreateDto, quoteId, bundleId);
    this.bundleUuidSubject.next(bundleAddServiceDto.Uuid as string); // Emit the UUID through the Observable
    localStorage.setItem(prefix + bundleAddServiceDto.Uuid, JSON.stringify(bundleAddServiceDto));
    return bundleAddServiceDto;
  }

  /**
   * Saves the service to the bundle DTO and sends the request to the API.
   * @param bundleTemporaryId - The UUID of the temporary bundle.
   * @param createTravelServiceDto - The DTO containing the details of the service to save.
   * @param isTravelServiceIds - Boolean flag that determines the patch path which return travel service ids
   * @returns An Observable that resolves to the updated quote bundle DTO or null if an error occurs.
   */
  saveServiceToBundleDto(bundleTemporaryId: string, createTravelServiceDto: any, isTravelServiceIds: boolean = false)
    : Observable<QuoteBundleDto | null | number[]> {
    const bundleAddServiceModel = this.getBundle(bundleTemporaryId);
    if (!bundleAddServiceModel) {
      return of(null);
    }

    this.bundleUuidSubject.complete();
    this.clearTemporaryBundle();

    if (bundleAddServiceModel.bundleId && !isTravelServiceIds) {
      const bundleCreateDto = bundleAddServiceModel.bundleCreateDto as BundlePatchDto;
      bundleCreateDto.TravelServicesToCreate = createTravelServiceDto;

      return this.bundlesApi.updateBundle(bundleAddServiceModel.quoteId, bundleAddServiceModel.bundleId, bundleCreateDto);
    } else if (bundleAddServiceModel.bundleId && isTravelServiceIds) {
      const bundleCreateDto = bundleAddServiceModel.bundleCreateDto as BundlePatchDto;
      bundleCreateDto.TravelServicesToCreate = createTravelServiceDto;
      return this.bundlesApi.updateBundleAndReturnTravelServiceIds(bundleAddServiceModel.quoteId,
        bundleAddServiceModel.bundleId, bundleCreateDto);
    } else {
      const bundleCreateDto = bundleAddServiceModel.bundleCreateDto as BundleCreateDto;
      bundleCreateDto.TravelServicesToCreate = createTravelServiceDto;
      return this.bundlesApi.addBundleToQuote(bundleAddServiceModel.quoteId, bundleCreateDto);
    }
  }
}
