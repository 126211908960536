import { Pipe, PipeTransform } from '@angular/core';
import * as accounting from 'accounting';
import { Money } from '../models/money.model';

/**
 * Takes a MoneyDto object and creates something presentable from it.
 * */
@Pipe({ name: 'money' })
export class MoneyPipe implements PipeTransform {
    transform(money: Money, extension?: string): string {
        if (!money) {
            return "";
        }

        if (extension === 'absolute') {
            return `${money.CurrencyCode} ${accounting.formatMoney(Math.abs(money.Amount), money.Symbol, 2, ',', '.')}`;
        }

        return `${money.CurrencyCode} ${accounting.formatMoney(money.Amount, money.Symbol, 2, ',', '.')}`;
    }
}