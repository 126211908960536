import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { AgentCookie } from '../models/agent-cookie.model';
import { Observable, of } from 'rxjs';

/**
 * Allows for querying of the browser cookies for agent data.
 * 
 * Note that we should be moving away from storing agent data in cookies, as simply
 * querying for the API of the currently logged in agent should suffice.
 * */
@Injectable({ providedIn: 'root' })
export class AgentCookieService {
    constructor(private cookieService: CookieService) {

    }

    setSelectedAgents(agents: { all: boolean, ids: number[] }): void {
        this.cookieService.put('SelectedAgents', `Agents=${JSON.stringify(agents)}`, { storeUnencoded: true });
    }

    getSelectedAgents(): { all: boolean, ids: number[] } {
        let selectedAgents = this.cookieService.get('SelectedAgents')?.replace('Agents=', '');

        if (selectedAgents) {
            if (selectedAgents.length > 0 && selectedAgents.startsWith('{')) {
                return JSON.parse(selectedAgents);
            } else {
                // This is to take care of the old format, as a backup. Eventually we should remove this.
                let agentIds = selectedAgents.split(',').map(id => parseInt(id, 10));
                return { all: false, ids: agentIds };
            }
        }

        return { all: false, ids: [] };
    }

    setAgentCookieCommissionVisibility(isVisible) {
        this.getAgentCookie().subscribe(cookie => {
            cookie['Preferences']['DisplayCommission'] = isVisible;
            this.cookieService.put('user', this._convertCookieToString(cookie), { storeUnencoded: true });
        });
    }

    setAdminVisibility(isVisible) {
        this.getAgentCookie().subscribe(cookie => {
            cookie['Preferences']['DisplayAdmin'] = isVisible;
            this.cookieService.put('user', this._convertCookieToString(cookie), { storeUnencoded: true });
        });
    }

    get isImpersonating(): boolean {
        var impersonationCookie = this.cookieService.get('_AUTH');

        return !!impersonationCookie;
    }

    getAgentCookie(): Observable<AgentCookie | null> {
        var userCookie = this.cookieService.get('user');

        if (!userCookie) {
            return of(null);
        }

        var agentCookie = new AgentCookie();

        Object.assign(agentCookie, this._decodeCookieString(userCookie));

        agentCookie.AffiliateType = (agentCookie.AffiliateType) ? +agentCookie.AffiliateType : null;

        return of(agentCookie);
    }

    removeSelectedAgentsCookie(): void {
        this.cookieService.remove('SelectedAgents');
    }

    _convertCookieToString(cookie: Object): string {
        const cookieKeys = Object.keys(cookie);
        let cookieString = '';

        cookieKeys.forEach((key, index) => {
            if (typeof cookie[key] === 'string') {
                cookieString = cookieString + `${key}=${cookie[key]}`;
            }
            else if (key === 'Roles') {
                cookieString = cookieString + `${key}={"RolesForUser":${JSON.stringify(cookie[key])}}`;
            }
            else if (key === 'Preferences') {
                cookie[key]['HotelLoyaltyNumbers'] = JSON.stringify(cookie[key]['HotelLoyaltyNumbers'])
                cookieString = cookieString + `${key}=${JSON.stringify(cookie[key])}`;
            }
            else {
                cookieString = cookieString + `${key}=${JSON.stringify(cookie[key])}`;
            }
            if (index < cookieKeys.length - 1) {
                cookieString = cookieString + `&`;
            }
        });

        return cookieString;
    }

    _decodeCookieString(str: string): Object {
        let kvps = str.split('&');
        let obj = {};

        kvps.forEach(entry => {
            var kvp = entry.split('=');
            obj[kvp[0]] = kvp[1];
        });

        obj['Roles'] = JSON.parse(obj['Roles']).RolesForUser;
        obj['OfficeCanSellTravelServices'] = JSON.parse(obj['OfficeCanSellTravelServices']);
        obj['SupportedCurrencies'] = JSON.parse(obj['SupportedCurrencies']);
        obj['Preferences'] = obj['Preferences'] ? JSON.parse(obj['Preferences']) : {};
        obj['Preferences']['HotelLoyaltyNumbers'] = obj['Preferences']['HotelLoyaltyNumbers'] ? JSON.parse(obj['Preferences']['HotelLoyaltyNumbers']) : [];

        return obj;
    }
}