<div class="login-page" #loginPage>
    <header class="text-center">
        <img src="/Content/images/ADX-Logo.svg" style="width: 80px; margin:auto;" />
        <span class="subtext">The World's #1 Advisor Experience</span>
    </header>

    <div class="container">
        <div class="row justify-content-center" style="margin-top: 20vh;">
            <div class="col-sm-12 col-md-5">
                <div class="login-container">
                    <ng-container *ngIf="!resetPasswordToken; else resetPasswordFlow">
                        <h1>Advisor Sign In</h1>
                        <adx-login (login)="onLogin()" [(state)]="loginState"></adx-login>
                    </ng-container>

                    <ng-template #resetPasswordFlow>
                        <h1>Password Reset</h1>
                        <ng-container *ngIf="!isTokenValid; else showChangePassword">
                            <p>
                                This password reset link has expired, either it has already been used or it was sent more than 24 hours ago. Use
                                the "Forgot your password" link on the advisor sign in page to have a new link emailed to you, or contact ADX support or
                                your manager to provide a new reset link.
                            </p>

                            <button type="button" class="btn btn-link" (click)="onBackToLogin()">Back to Advisor Sign In</button>
                        </ng-container>

                        <ng-template #showChangePassword>
                            <adx-change-password (passwordChanged)="onPasswordChanged($event)"></adx-change-password>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<footer>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-8 offset-md-2 text-center">
                <span>
                    TripArc is one of the leading and fastest-growing global travel
                    organizations. We empower our partner companies to drive competitive
                    advantage and business growth by providing access to best-in-class
                    technology solutions.
                </span>
                <br />
                <span *ngIf="config">
                    version: {{ config.BuildVersion }}
                </span>
            </div>
        </div>
    </div>
</footer>
