import { Component, Input } from '@angular/core';

/**
 * Displays an ADX-styled panel that allows for header and body customization.
 *
 * In general, most of the content on the site should be located in these panels.
 */
@Component({
    selector: 'adx-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss']
})
export class PanelComponent {
    @Input() iconClass: string;
    @Input() minWidth: string = 'fit-content';
}