import { Component, Input } from '@angular/core';

/**
 * Displays an ADX-styled notice; usually a warning or info tip of some kind that should be displayed
 * on a page or in a modal.
 */
@Component({
    selector: 'adx-notice',
    templateUrl: './notice.component.html',
    styleUrls: ['./notice.component.scss']
})
export class NoticeComponent {
    @Input() noticeType: 'info' | 'warning' | 'error' | 'success' | 'danger';
    @Input() invertColors: boolean = false;
    @Input() iconClass: string = null;

    get noticeIconString(): string {
        if (this.iconClass) {
            return this.iconClass;
        }

        switch (this.noticeType) {
            case 'info':
                return 'fa fa-compass';

            case 'warning':
                return 'fa fa-info-circle';

            case 'error':
                return 'fa fa-exclamation-circle';

            case 'danger':
                return 'fa fa-exclamation-circle';

            case 'success':
                return 'fa fa-check-circle';
        }
    }
}
