import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RouterEvent} from '@angular/router';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { MomentTimezoneData } from './modules/core/data/moment-timezone-data';
import { CoreApiService } from './modules/core/services/core-api.service';
import { ServerVersionConfiguration } from './modules/core/models/server-version-configuration.model';
import { AgentCookieService } from './modules/agent/services/agent-cookie.service';
import { AgentCookie } from './modules/agent/models/agent-cookie.model';

/**
 * Bootstrapping component for the ADX Angular project. This is the component that contains
 * the master template and handles page-level events as needed.
 *
 * TODO: Rename to adx-app, and other cleanups.
 */
@Component({
    selector: 'adx-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    $serverVersion: Observable<ServerVersionConfiguration>;
    loading: boolean = true;
    hasError: boolean = false;
    loaderImage: any = './../../Content/images/loading-bar.gif';

    constructor(
        private coreApi: CoreApiService,
        private agentCookieService: AgentCookieService,
        private router: Router) {
        router.events.subscribe((routerEvent: Event | RouterEvent) => {
            this.checkRouterEvent(routerEvent);
        });
    }

    ngOnInit(): void {
        this.$serverVersion = this.coreApi.getServerVersion();
        moment.tz.load(MomentTimezoneData);
    }

    ngAfterViewInit(): void {

    }

    initializeTrainJs(): void {
        let w = <any>window;

        this.agentCookieService.getAgentCookie().subscribe((c: AgentCookie) => {
            if (!c) {
                // We haven't logged in yet.
                return;
            }

            /*
             * TODO: Wire in Admin vs Employee vs IC vs Manager vs Regional Manager
             */
            w.WhatFixInfo = {
                accountId: c.UserId,
                officeName: c.OfficeName,
                agencyName: c.AgencyName,
                email: c.Email,
                affiliateType: c.AffiliateType,
                adUserName: c.UserAD,
                agentType: c.AgentType
            };
        });
    }

    checkRouterEvent(routerEvent: Event | RouterEvent): void {
        if (routerEvent instanceof NavigationStart) {
            this.loading = true;
        }
        if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel) {
            this.loading = false;

            this.initializeTrainJs();
        }
        if (routerEvent instanceof NavigationError) {
            this.loading = false;
        }
    }

    get showEnvironmentRibbon(): boolean {
        return window.location.host !== 'adx.traveledge.com' && window.location.host !== 'adxtravel.com' && window.location.host !== 'www.adxtravel.com' ;
    }
}
