import { Component, AfterViewInit, Renderer2, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CoreApiService } from '../../../core/services/core-api.service';
import { ServerVersionConfiguration } from '../../../core/models/server-version-configuration.model';
import { IAdxRequestErrorContext } from '../../../core/models/adx-request-error-context';
import { LoginApiService } from '../../services/login-api.service';
import { AgentCookieService } from '../../../agent/services/agent-cookie.service';

/**
 * Application login page.
 * */
@Component({
    selector: 'adx-login-page',
    templateUrl: './login.page.component.html',
    styleUrls: ['./login.page.component.scss']
})
export class LoginPageComponent implements AfterViewInit, OnInit {
    @ViewChild('loginPage', { static: true }) loginPageElement: ElementRef;

    redirectUrl: string;

    resetPasswordToken: string | null;

    isTokenValid: boolean | null = null;

    config: ServerVersionConfiguration;

    loginState = 'login';

    constructor(
        private renderer: Renderer2,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private coreApiService: CoreApiService,
        private loginApi: LoginApiService,
        private agentCookieService: AgentCookieService) {
        this.route.queryParamMap.subscribe(p => {
            this.redirectUrl = p.get('ReturnUrl') || '/';
            this.resetPasswordToken = p.get('token') || null;

            // If we are in the password reset flow, then validate that the reset token
            // is still valid. This flow hides the login component and diplays the password reset
            // component instead.
            if (this.resetPasswordToken) {
                this.loginApi.validateResetPasswordToken(this.resetPasswordToken).subscribe(resp => {
                    this.isTokenValid = resp;
                }, (error: IAdxRequestErrorContext) => {
                    error.silent = true;
                    this.isTokenValid = false;
                });
            }
        });

        this.titleService.setTitle(`Welcome « ADX`);

        this.coreApiService.getServerVersion().subscribe(resp => this.config = resp, (resp: IAdxRequestErrorContext) => { resp.silent = true });
    }

    ngOnInit() {
        this.agentCookieService.getAgentCookie().subscribe(c => {
            if (c && c.usesExternalLogin) {
                window.location.href = '/session-timeout';
            }
        });
    }

    ngAfterViewInit(): void {
        let randImage = Math.floor(Math.random() * 10) + 1;

        this.renderer.setStyle(
            this.loginPageElement.nativeElement,
            'backgroundImage',
            `url(/Content/images/backgrounds/${randImage}.jpg)`
        );

        this.renderer.setStyle(
            this.loginPageElement.nativeElement,
            'backgroundSize',
            `cover`
        );

        this.renderer.setStyle(
            this.loginPageElement.nativeElement,
            'backgroundRepeat',
            `no-repeat`
        );
    }

    onLogin(): void {
        this.redirect();
    }

    onBackToLogin(): void {
        this.resetPasswordToken = null;
        this.isTokenValid = null;
    }

    onPasswordChanged(newPassword: string): void {
        this.loginApi.setNewPassword({ Token: this.resetPasswordToken, NewPassword: newPassword }).subscribe(resp => {
            const acceptedTerms = !!resp;

            if (acceptedTerms) {
                this.redirect();
            } else {
                this.resetPasswordToken = null;
                this.isTokenValid = null;

                this.loginState = 'updated-terms';
            }
        });
    }

    resetState(): void {
        window.localStorage.removeItem('ADX-HIDE-ADMIN');
    }

    redirect(): void {
        this.resetState();

        if (this.redirectUrl === '/') {
            this.router.navigate([this.redirectUrl]);
        } else {
            window.location.href = this.redirectUrl;
        }
    }
}
