import { PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';

/**
 * Displayed upon 404 responses at the routing/resolution level.
 * */
@Component({
  selector: 'adx-not-found-page',
  templateUrl: './not-found.page.component.html',
  styleUrls: ['./not-found.page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  constructor(
    private pathLocationStrategy: PathLocationStrategy
  ) { }

  displayNotFoundTemplate: boolean = false;

  ngOnInit() {
    this.reroute();
  }

  //intercept all not found && not prefixed pages and add /v1 prefix
  reroute() {
    const path = this.pathLocationStrategy.path();
    if (!path.startsWith('/v1')) {      
      window.location.href = `/v1${path}`;
    } else {
      this.displayNotFoundTemplate = true;
    }
  }
}
