<adx-error-header *ngIf="hasError"></adx-error-header>

<ng-container *ngIf="$serverVersion|async as serverVersion">
    <adx-environment-ribbon *ngIf="showEnvironmentRibbon" [serverConfiguration]="serverVersion"></adx-environment-ribbon>
</ng-container>

<adx-page-loader *ngIf="loading"></adx-page-loader>

<ng-container *ngIf="!loading">
    <router-outlet></router-outlet>
</ng-container>