import { Injectable, EventEmitter } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, retry, finalize, delay, tap, flatMap } from 'rxjs/operators';
import { LoginErrorService } from '../../login/services/login-error.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IAdxRequestErrorContext } from '../models/adx-request-error-context';
import { ErrorModalComponent } from '../modals/error/error.modal.component';
import { ErrorLogService } from '../services/error-log.service';
import { AgentCookieService } from '../../agent/services/agent-cookie.service';
import { AgentCookie } from '../../agent/models/agent-cookie.model';

/**
 * Handles requests and errors when interacting with the ADX HTTP API.
 *
 * The default behaviour when an error status code returns is for a modal to be spawned.
 * A subscriber wishing to handle the error and prevent default behavior can implement the `error`
 * handler when calling subscribe(), and set the `silent` flag to true.
 */
@Injectable({ providedIn: 'root' })
export class AdxApiHttpInterceptor implements HttpInterceptor {
    constructor(
        private cookieService: AgentCookieService,
        private loginError: LoginErrorService,
        private errorLog: ErrorLogService,
        private modalService: NgbModal) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const errorObject: IAdxRequestErrorContext = {
            error: null,
            silent: false,
            onContinue: new EventEmitter<any>()
        };

        return next
            .handle(req)
            .pipe(
                //retry(1),
                catchError((error: HttpErrorResponse) => {
                    errorObject.error = error;

                    //
                    // Handle failed auth login calls separately, because we don't
                    // want these to be queued up, we simply want to let the
                    // caller know they happened, and the caller can take care
                    // of handling them.
                    //
                    if (error.url.indexOf('/auth') !== -1) {
                        errorObject.silent = true;
                        return throwError(errorObject);
                    }

                    if (error.status === 401) {
                        return this.cookieService.getAgentCookie().pipe(
                            flatMap(c => {
                                // Ensemble agents are to be redirected to a session expired page.
                                if (c && c.isEnsembleAgent) {
                                    errorObject.silent = true;
                                    window.location.href = '/session-timeout';

                                    return throwError(errorObject);
                                } else {
                                    errorObject.error = null;
                                    return this.loginError.enqueue(req, next);
                                }
                            })
                        );
                    }

                    //
                    // Log API errors so that we can retrieve them later if we need them.
                    //
                    if (error.url.indexOf('/api') !== -1) {
                        this.errorLog.log(errorObject.error);
                    }

                    return throwError(errorObject);
                }),
                finalize(() => {
                    // Do nothing if we are waiting for the user to log in.
                    if (this.loginError.hasQueueItems) {
                        return;
                    }

                    if (errorObject.error && !errorObject.silent) {
                        const modalRef = this.modalService.open(ErrorModalComponent, {
                            size: 'lg',
                            backdrop: 'static'
                        });

                        modalRef.componentInstance.errorContext = errorObject;
                    }
                })
            );
    }
}
