<form class="form" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <adx-notice [noticeType]="'info'" [invertColors]="true">
        Password must be <strong>at least 8 characters</strong> in length and include:

        <ul>
            <li>One upper case letter</li>
            <li>One lower case letter</li>
            <li>One digit or special character</li>
        </ul>
    </adx-notice>

    <div class="form-group">
        <label>New Password</label>

        <div class="input-group">
            <input [type]="showingPassword ? 'text' : 'password'" class="form-control" formControlName="Password" />

            <div class="input-group-append" title="Show/Hide Password" (click)="onTogglePasswordVisibility()">
                <span class="input-group-text"><i class="fas" [class.fa-eye]="!showingPassword" [class.fa-eye-slash]="showingPassword"></i></span>
            </div>
        </div>
    </div>

    <div class="form-group">
        <label>Confirm New Password</label>

        <div class="input-group">
            <input [type]="showingPassword ? 'text' : 'password'" class="form-control" formControlName="ConfirmPassword" />

            <div class="input-group-append" title="Show/Hide Password" (click)="onTogglePasswordVisibility()">
                <span class="input-group-text"><i class="fas" [class.fa-eye]="!showingPassword" [class.fa-eye-slash]="showingPassword"></i></span>
            </div>
        </div>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="!passwordForm.valid || loading"><i class="fasfa-spinner fa-spin" *ngIf="loading"></i> Change Password and Sign In</button>
</form>