import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TravelServiceBundlePriceDto } from "../models/travel-service-bundle-price-dto";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { BundlePatchDto } from "../../trip-services-common/models/bundle-patch.model";
import { QuoteBundleDto } from "../models/quote-bundle-dto.model";
import { BundlePricePreviewCalculationRequestDto } from "../models/bundle-price-preview-calculation-request-dto.model";
import { Money } from "../../shared/models/money.model";
import { BundleCreateDto } from "../models/bundlle-create-dto.model";
import { BundlePricePreviewDto } from "../models/bundle-price-preview-dto";

@Injectable({ providedIn: 'root' })
export class BundleQuoteApiService {

  private get apiUrl() {
    return environment.baseApiUrl;
  }

  constructor(private http: HttpClient) {
  }

  getBundlePricesByCurrency(quoteId: number, currencyId: number): Observable<TravelServiceBundlePriceDto[]> {
    return this.http.get<TravelServiceBundlePriceDto[]>(`${this.apiUrl}/quotes/${quoteId}/bundlesPricing/travelServicesBundlePrice?currencyId=${currencyId}`);
  }

  calculateBundlePricePreview(quoteId: number, dto: BundlePricePreviewCalculationRequestDto): Observable<BundlePricePreviewDto> {
    return this.http.post<BundlePricePreviewDto>(`${this.apiUrl}/quotes/${quoteId}/bundlesPricing/calculatePricePreview`, dto);
  }

  updateBundle(quoteId: number, bundleId: number, data: BundlePatchDto): Observable<QuoteBundleDto> {
    return this.http.patch<QuoteBundleDto>(`${this.apiUrl}/quotes/${quoteId}/bundles/${bundleId}`, data);
  }

  updateBundleAndReturnTravelServiceIds(quoteId: number, bundleId: number, data: BundlePatchDto): Observable<number[]> {
    return this.http.patch<number[]>(`${this.apiUrl}/quotes/${quoteId}/bundles/${bundleId}/travelServiceIds`, data);
  }

  addSuggestedServiceToBundle(quoteId: number, bundleId: number, travelServiceId: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/quotes/${quoteId}/bundles/${bundleId}/suggested/${travelServiceId}/add`, null);
  }

  addBundleToQuote(quoteId: number, data: BundleCreateDto): Observable<QuoteBundleDto> {
    return this.http.post<QuoteBundleDto>(`${this.apiUrl}/quotes/${quoteId}/bundle`, data);
  }

  removeTravelServiceFromBundle(quoteId: number, bundleId: number, travelServiceId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/quotes/${quoteId}/bundles/${bundleId}/travelServices/${travelServiceId}/remove`);
  }

  removeBundleFromQuote(quoteId: number, bundleId: number): Observable<QuoteBundleDto> {
    return this.http.delete<QuoteBundleDto>(`${this.apiUrl}/quotes/${quoteId}/bundles/${bundleId}`);
  }

}
