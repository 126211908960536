import { Component, Input, HostBinding, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IAdxRequestErrorContext } from '../../models/adx-request-error-context';
import { AgentSelectionListService } from '../../../agent/services/agent-selection-list.service';
import { RequestSupportApiService } from '../../../shared/services/request-support-api.service';
import { Agent } from '../../../agent/models/agent.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SupportTeamTypes } from '../../../shared/enums/support-team-types.enum';
import { ServerVersionConfiguration } from '../../../core/models/server-version-configuration.model';
import { CoreApiService } from '../../../core/services/core-api.service';
/**
 * Displays the error modal due to a front-end error, or when the back-end returns an error code.
 * */
@Component({
    selector: 'adx-error-modal',
    templateUrl: './error.modal.component.html',
    styleUrls: ['./error.modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
    @Input() errorContext: IAdxRequestErrorContext;

    @HostBinding('class.error-type-error') isError: boolean = false;

    @HostBinding('class.error-type-warning') isWarning: boolean = false;

    @HostBinding('class.error-type-informational') isInfo: boolean = false;

    errorForm: UntypedFormGroup = new UntypedFormGroup({
        IssueDescription: new UntypedFormControl(''),
        OnBehalfOfAgentId: new UntypedFormControl(''),
        CCEmail: new UntypedFormControl(''),
        SupportTeamType: new UntypedFormControl(''),
        IsUrgent: new UntypedFormControl(false)
    });

    agents: Agent[] = [];

    apiBusyState: boolean = false;

    apiResponseReceived: boolean = false;

    SupportTicketIdentifier: string;

    serverConfiguration: ServerVersionConfiguration;
    constructor(
        public activeModal: NgbActiveModal,
        private agentSelection: AgentSelectionListService,
        private requestSupportApiService: RequestSupportApiService,
        private router: Router,
        private route: ActivatedRoute,
        private coreApiService: CoreApiService
    ) {
        this.coreApiService.getServerVersion().subscribe(resp => this.serverConfiguration = resp, (resp: IAdxRequestErrorContext) => { resp.silent = true });
    }

    ngOnInit() {

        this.errorForm.get('SupportTeamType').setValue(this.errorContext.error.SupportTeamType);

        this.agentSelection.getAgentList().subscribe(a => {
            this.agents = a;
            this.errorForm.get('OnBehalfOfAgentId').setValue(a[0].AgentId);
        });

        let warningStatusCodes = [401, 400, 409];

        if (this.isInformational) {
            this.isInfo = true;
        }
        else if (warningStatusCodes.indexOf(this.errorContext.error.status) !== -1) {
            this.isWarning = true;
        }
        else {
            this.isError = true;
        }
    }

    get errorMessage(): any {
        var error = this.errorContext.error.error;
        error.UserDisplayMessage = error?.UserDisplayMessage?.replace('aDX', 'ADX');
        return (typeof error === 'string' || error instanceof String) ? JSON.parse(<any>error) : error;
    }

    get developerMessage(): any {
        return this.errorMessage.DeveloperErrorMessage;
    }

    get isReviewedLabel(): any {
        return this.developerMessage.Reviewed ? '(Reviewed)' : '(Not Reviewed)';
    }

    get isAirSupportTeam(): boolean {
        return this.errorContext.error.SupportTeamType == SupportTeamTypes.AirSupportTeam;
    }

    get isInformational(): boolean {
        return this.errorMessage.ErrorType === 'Informational';
        //return this.errorContext.error.ErrorType === 'Informational'; 
    }

    onClose(): void {
        this.activeModal.dismiss();
    }

    onContinue(): void {
        this.activeModal.dismiss();
        this.errorContext.onContinue.emit();
    }

    onSendSupport(): void {

        let searchCriteria = '';

        const quoteId = this.route.snapshot.queryParamMap.get("quoteId") ? this.route.snapshot.queryParamMap.get("quoteId") : '';
        const travelServiceId = this.route.snapshot.queryParamMap.get("serviceId") ? this.route.snapshot.queryParamMap.get("serviceId") : '';

        if (this.route.snapshot.queryParamMap.get("asc")) {
            searchCriteria = this.route.snapshot.queryParamMap.get("asc");
        }
        if (this.route.snapshot.queryParamMap.get("hsc")) {
            searchCriteria = this.route.snapshot.queryParamMap.get("hsc");
        }

        let supportRequest = {
            OnBehalfOfAgentId: this.errorForm.value.OnBehalfOfAgentId,
            IssueDescription: this.errorForm.value.IssueDescription,
            IsUrgent: this.errorForm.value.IsUrgent,
            CCEmail: this.errorForm.value.CCEmail,
            SupportTeamType: this.errorForm.value.SupportTeamType,
            RequestId: this.errorMessage.RequestId,
            Url: this.router.url,
            QuoteId: quoteId,
            TravelServiceId: travelServiceId,
            SearchCriteria: searchCriteria,
            SendPublicNoteToAgent: true,
            UserAgent: "Unknown Browser"
        }

        this.apiBusyState = true;
        this.requestSupportApiService.sendSupportRequest(supportRequest).subscribe(
            resp => {
                this.apiBusyState = false;
                this.SupportTicketIdentifier = resp.SupportTicketIdentifier;
                this.apiResponseReceived = true;
            },
            err => {
                this.apiBusyState = false;
                this.apiResponseReceived = true;
            });

    }
}
