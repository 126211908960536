import { Component, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Displays the login component in a modal form.
 * */
@Component({
    selector: 'adx-login-modal',
    templateUrl: './login.modal.component.html',
    styleUrls: ['./login.modal.component.scss']
})
export class LoginModalComponent {
    @Output() login: EventEmitter<any> = new EventEmitter<any>();

    constructor(private modal: NgbActiveModal) {

    }

    onLogin(): void {
        this.login.emit();
    }
}
