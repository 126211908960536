import { IApiDeserializable } from '../../core/models/api-deserializable';
import { Address } from '../../shared/models/address.model';

/**
 * Agent's communication info.
 * */
export class AgentCommunicationProfile implements IApiDeserializable {
    AgentId: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Email?: string;
    EmailSignature?: string;
    CellPhone?: string;
    RegulatoryInformation?: string;
    IsBusinessNameEditAllowed? : boolean;
    CompanyName?: string;
    OfficePhone?: string;
    BannerImage?: string;
    CoverImage?: string;
    CustomCoverImage?: string;
    FooterImage?: string;
    TitleImage?: string;
    ProfileImage?: string;
    AgentBio?: string;
    DayAtLaisureMessage?: string;
    Phone?: string;
    Mobile?: string;
    Fax?: string;
    Address?: Address;
    UseVirtuosoProfileImage?: boolean;


    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
