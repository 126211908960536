import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Third Party Libraries
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Modules
import { SharedModule } from '../shared/shared.module';

// Components
import { CreateUpdateClientComponent } from './components/create-update-client/create-update-client.component';
import { AddressGridComponent } from './components/address-grid/address-grid.component';
import { ClientModalComponent } from './modals/client/client.modal.component';
import { ClientListModalComponent } from './modals/client-list/client-list.modal.component';
import { ClientSearchResultsListModalComponent } from './modals/client-search-results-list/client-search-results-list.modal.component';
import { CompanionModalComponent } from './modals/companion/companion.modal.component';
import { CompanionSearchResultsListModalComponent } from './modals/companion-search-results-list/companion-search-results-list.modal.component';
import { CreateUpdateCompanionComponent } from './components/create-update-companion/create-update-companion.component';
import { MarketingFieldsModalComponent } from './modals/marketing-fields-modal/marketing-fields-modal.component';

/**
 * Pertains to everything to do with clients and travelers.
 * */
@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        NgbModule,
        SharedModule
    ],
    declarations: [
        ClientListModalComponent,
        ClientSearchResultsListModalComponent,
        ClientModalComponent,
        CompanionSearchResultsListModalComponent,
        CompanionModalComponent,
        CreateUpdateClientComponent,
        CreateUpdateCompanionComponent,
        AddressGridComponent,
        MarketingFieldsModalComponent
    ],
    exports: [
        ClientListModalComponent,
        ClientSearchResultsListModalComponent,
        ClientModalComponent,
        CompanionSearchResultsListModalComponent,
        CompanionModalComponent,
        CreateUpdateClientComponent,
        CreateUpdateCompanionComponent,
        AddressGridComponent,
        MarketingFieldsModalComponent
    ]
})
export class PeopleModule {

}
