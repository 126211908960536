import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, share} from 'rxjs/operators';
import {AdminMessage} from '../../admin/models/admin-message.model';
import {ServerVersionConfiguration} from '../models/server-version-configuration.model';
import {IAdxRequestErrorContext} from "../models/adx-request-error-context";

/**
 * Service to the Core ADX HTTP API.
 */
@Injectable({providedIn: 'root'})
export class CoreApiService {
  apiUrl = '/api';

  constructor(private http: HttpClient) {
  }

  /**
   * Returns the API configuration and version information that the front-end cares about.
   * */
  getServerVersion(): Observable<ServerVersionConfiguration> {
    return this.http.get<ServerVersionConfiguration>(`${this.apiUrl}/version`)
      .pipe(map(r => new ServerVersionConfiguration().deserialize(r)));
  }

  getSystemNotifications(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/systemnotifications`);
  }

  getAdminMessagesByUserId(userId: number): Observable<AdminMessage[]> {
    return this.http.get<AdminMessage[]>(`${this.apiUrl}/admin-messages/by-user-id?userId=${userId}`)
      .pipe(
        catchError((ctx: IAdxRequestErrorContext) => {
          // No admin messages for this user; silently return null.
          ctx.silent = true;
          return of(null);
        })
      );
  }
}
