import { Injectable } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Agent } from '../models/agent.model';
import { Delegator } from '../models/delegator.model';
import { PublicProfileAttributeTypes } from '../enums/public-profile-attribute-types.enum';
import { IAgentPublicProfile, IAgentPublicProfileAttribute } from '../models/agent-public-profile.model';
import { ClientAndAgentSuggestion } from '../models/quote-suggestion.model';
import { ChangePasswordForm } from '../models/profile.model';
import { LogoImageTypes } from '../enums/logo-image-types.enum';
import { IQuoteColorPalette } from '../components/quote-color-palette/quote-color-palette.component';
import { Delegation } from '../models/delegation.model';
import { AgentMessageTemplate } from '../../shared/models/agent-message-template';
import { AgentPlusValue } from "../../shared/models/agent-plus-value.model";
import { DefaultPlusValueGroup } from "../../shared/models/default-plus-value-group.model";
import { CustomPlusValueGroup } from "../../shared/models/custom-plus-value-group.model";
import { AgentBusinessProfile } from "../models/agent-business-profile.model";

/**
 * Exposes the API for Agent details.
 */
@Injectable({providedIn: 'root'})
export class AgentApiService {
  apiUrl = "/api";

  constructor(private http: HttpClient) {
  }

  /**
   * Returns an agent given an id.
   */
  getProfileForAgentId(id: number): Observable<Agent> {
    return this.http.get<Agent>(`${this.apiUrl}/agents/${id}`)
      .pipe(map(resp => new Agent().deserialize(resp)));
  }

  //Public Profile
  getPublicProfileForAgentEmail(email: string): Observable<IAgentPublicProfile> {
    return this.http.get<IAgentPublicProfile>(`${this.apiUrl}/publicProfile/Get?emailAddress=${email}`)
  }

  getPublicProfileForAgentId(agentId: number): Observable<IAgentPublicProfile> {
    return this.http.get<IAgentPublicProfile>(`${this.apiUrl}/publicProfile/GetProfileByAgentId?agentId=${agentId}`)
  }

  savePublicProfileForAgent(profileDto: IAgentPublicProfile): Observable<IAgentPublicProfile> {
    return this.http.post<IAgentPublicProfile>(`${this.apiUrl}/publicProfile/updateProfile`, profileDto)
  }

  createPublicProfileForAgent(profileId, profileDto: IAgentPublicProfile): Observable<IAgentPublicProfile> {
    return this.http.post<IAgentPublicProfile>(`${this.apiUrl}/publicProfile/CreatePublicProfile?agentId=${profileId}`, profileDto)
  }

  updatePublicProfileImage(profileId: number, formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/publicProfile/UpdateProfileImage?profileId=${profileId}`, formData)
  }

  getPublicProfileAttributes(type: PublicProfileAttributeTypes): Observable<{
    DataCatalogDto: IAgentPublicProfileAttribute[]
  }> {
    return this.http.get<{ DataCatalogDto: IAgentPublicProfileAttribute[] }>(
      `${this.apiUrl}/publicProfile/GetDataCatalog?type=${type}`)
  }

  //End Public Profile

  isCanadianAgent(agentId: number) {
    return this.http.get<boolean>(`${this.apiUrl}/validation/IsCanadianAgent`, {params: {'AgentId': agentId}});
  }

  isFreeSupport247(agentId: number) {
    return this.http.get<boolean>(`${this.apiUrl}/validation/IsFreeSupport247`, { params: { 'AgentId': agentId } });
  }

  //Agent Message Templates
  getMessageTemplates(agentId: number): Observable<AgentMessageTemplate[]> {
    return this.http.get<any>(`${this.apiUrl}/agents/${agentId}/messagetemplates`);
  }

  getMessageTemplatesForPlanningFee(agentId: number, typeId: number) {
    let url = `/agents/${agentId}/messagetemplates`;
    if (typeId) {
      url += '?messageTemplateTypeId=' + typeId;
    }
    return this.http.get<any>(`${this.apiUrl}${url}`);
  }

  saveMessageTemplatesForPlanningFee(agentId: number, data: any) {
    let url = `${this.apiUrl}/agents/${agentId}/messagetemplates`;
    return this.http.put<any>(url, data);
  }

  deleteMessageTemplatesForPlanningFee(agentId: number, data: any) {
    let url = `${this.apiUrl}/agents/${agentId}/messagetemplates`;
    return this.http.delete<any>(url, {body: data});
  }

  getItineraryNotesByAgentId(agentId: number): Observable<AgentMessageTemplate[]> {
    return this.http.get<any>(`${this.apiUrl}/agents/${agentId}/messagetemplates?messageTemplateTypeId=1`);
  }

  updateMessageTemplate(agentId: number, data: AgentMessageTemplate): Observable<AgentMessageTemplate> {
    return this.http.put<AgentMessageTemplate>(`${this.apiUrl}/agents/${agentId}/messagetemplates`, data)
  }

  deleteMessageTemplate(agentId: number, dto: any): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/agents/${agentId}/messagetemplates`, {body: dto});
  }

  //END Agent Message Templates

  getNewValidationRulesForUrl(req: string, opts: any) {
    return this.http.get<any>(`${this.apiUrl}/validation/getfrompath?path=${req}${opts}`);
  }

  /**
   * Fetch the client & agent suggestion
   **/
  fetchSearchFilter(userId: number): Observable<ClientAndAgentSuggestion> {
    return this.http.get<ClientAndAgentSuggestion>(`${this.apiUrl}/quotes/searchfilter/${userId}`);
  }

  getAgentDetails(agentId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/agents/${agentId}`);
  }

  getAgentOfficeId(agentId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/agents/${agentId}/getOfficeId`);
  }

  search(searchCriteria: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/agents?searchCriteria=${searchCriteria}`);
  }

  getPreferencesForAgentId(agentId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/agents/${agentId}/preferences`);
  }

  changePasswordForAgentId(agentId: number, _data: ChangePasswordForm) {
    let url = `${this.apiUrl}/users/changepassword`;
    let data = {
      AgentId: agentId,
      CurrentPassword: _data?.CurrentPassword,
      NewPassword: _data?.Password
    };

    return this.http.post<any>(url, data);
  }

  sendResetPasswordEmail(email: string) {
    let url = `${this.apiUrl}/users/resetpassword`;
    let data = {Email: email};
    return this.http.post<any>(url, data);
  }

  savePreferencesForAgentId(agentId: number, data: any) {
    let url = `${this.apiUrl}/agents/${agentId}/preferences`;
    return this.http.post<any>(url, data);
  }

  saveCommunicationProfile(agentId: number, data: any): Observable<IAgentPublicProfile> {
    let url = `${this.apiUrl}/agents/${agentId}/communicationprofile`;
    return this.http.patch<any>(url, data);
  }

  saveAgentBio(agentId: number, data: any): Observable<any> {
    let url = `${this.apiUrl}/agents/${agentId}/customitindetails`;
    return this.http.put<any>(url, data);
  }

  saveAgentLoyaltyNumbersForAgentId(agentId: number, data: any) {
    let url = `${this.apiUrl}/agents/${agentId}/preferences/updateHotelLoyaltyNumbers`;
    return this.http.put<any>(url, data);
  }

  saveAgentEmailSignature(agentId: number, data: any) {
    let url = `${this.apiUrl}/agents/${agentId}/SaveEmailSignature`;
    return this.http.put<any>(url, data);
  }

  updateAgentProfileImage(agentId: number, data: any) {
    let url = `${this.apiUrl}/agentprofileimage/${agentId}/PostFile`;
    return this.http.post<any>(url, data);
  }

  deleteAgentProfileImage(agentId: number, data: any) {
    let url = `${this.apiUrl}/agentprofileimage/${agentId}/delete`;
    return this.http.post<any>(url, data);
  }

  getAgentLogoImage(agentId: number, type: LogoImageTypes) {
    return this.http.get<any>(`${this.apiUrl}/agents/${agentId}/logoImage?type=${type}`);
  }

  updateAgentLogoImage(agentId: number, type: LogoImageTypes, data: FormData): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/agents/${agentId}/logoImage/updateAgentLogoImage?type=${type}`, data);
  }

  deleteAgentLogoImage(agentId: number, type: LogoImageTypes, data: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/agents/${agentId}/logoImage/deleteAgentLogoImage?type=${type}`, data);
  }

  updateAgentActiveLogoType(agentId: number, type: LogoImageTypes): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/agents/${agentId}/logoImage/updateAgentActiveLogoType`, type);
  }

  //communication
  getAgentEmailTemplates(agentId: number) {
    let url = `${this.apiUrl}/agent/${agentId}/emailTemplates`;
    return this.http.get<any>(url);
  }

  saveAgentEmailTemplates(agentId: number, data: any) {
    let url = `${this.apiUrl}/agent/${agentId}/emailTemplates`;
    return this.http.put<any>(url, data);
  }

  //agent's authorized users delegations
  getAgentExistingDelegations(agentId: number): Observable<Delegation[]> {
    return this.http.get<Delegation[]>(`${this.apiUrl}/agents/${agentId}/delegation`);
  }

  //get delegators - who delegated to agent
  getDelegatorsForAgentId(id: number): Observable<Delegator[]> {
    return this.http.get<Delegator[]>(`${this.apiUrl}/agents/${id}/delegators`)
      .pipe(
        map((resp: []) => resp.map(d => new Delegator().deserialize(d)))
      );
  }

  cancelAgentDelegation(delegatedAgentId, agentId) {
    return this.http.delete<any>(`${this.apiUrl}/agents/${agentId}/delegation/${delegatedAgentId}`);
  }

  updateAgentSharingPreferences(agentId, data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/agents/${agentId}/enablesharingwithoffice`, data);
  }

  saveAgentDelegationPreferences(agentId, delegatedAgentId, data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/agents/${agentId}/delegation/${delegatedAgentId}`, data);
  }

  //my team
  getRegulatoryInformation(officeId: number) {
    let url = `${this.apiUrl}/offices/${officeId}/regulatory-information`;
    return this.http.get<any>(url);
  }

  getAdminNotes(officeId: number) {
    let url = `${this.apiUrl}/offices/${officeId}/branch-admin-notes`;
    return this.http.get<any>(url);
  }

  saveRegulatoryInformation(officeId: number, data: any) {
    let url = `${this.apiUrl}/offices/${officeId}/regulatory-information`;
    return this.http.put<any>(url, data);
  }

  saveAdminNotes(officeId: number, data: any) {
    let url = `${this.apiUrl}/offices/${officeId}/branch-admin-notes`;
    return this.http.put<any>(url, data);
  }

  getOfficeAgents(officeId: number) {
    const url = `${this.apiUrl}/office-management/agents?officeId=${officeId}`;
    return this.http.get<any>(url);
  }

  addOfficeAgent(agent: any) {
    const url = `${this.apiUrl}/office-management/addagent`;
    return this.http.post<any>(url, agent);
  }

  getAllOffices() {
    const url = `${this.apiUrl}/office-management/offices`;
    return this.http.get<any>(url);
  }

  getAgentCoverImage(agentId: number) {
    return this.http.get<any>(`${this.apiUrl}/agents/${agentId}/coverImage`);
  }

  deleteAgentCoverImage(agentId: number, body: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/agents/${agentId}/coverImage/deleteAgentCoverImage`, body);
  }

  updateAgentCoverImage(agentId: number, data: FormData): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/agents/${agentId}/coverImage/updateAgentCoverImage`, data);
  }

  //region AgentPlusValues

  getAgentPlusValue(agentId: number): Observable<AgentPlusValue> {
    return this.http.get<AgentPlusValue>(`${this.apiUrl}/agents/plus-value/get/${agentId}`);
  }

  createAgentPlusValue(data: AgentPlusValue): Observable<AgentPlusValue> {
    return this.http.post<AgentPlusValue>(`${this.apiUrl}/agents/plus-value/create`, data);
  }

  updateAgentPlusValue(data: AgentPlusValue): Observable<AgentPlusValue> {
    return this.http.put<AgentPlusValue>(`${this.apiUrl}/agents/plus-value/update`, data);
  }

  deleteAgentPlusValue(agentId: number): Observable<HttpStatusCode> {
    return this.http.delete<HttpStatusCode>(`${this.apiUrl}/agents/plus-value/delete/${agentId}`);
  }

  //end region AgentPlusValues

  //region PlusValueGroups
  getDefaultPlusValueGroup(officeId: number): Observable<DefaultPlusValueGroup> {
    return this.http.get<DefaultPlusValueGroup>(`${this.apiUrl}/agents/plus-value/group/default/get/${officeId}`);
  }

  getCustomPlusValueGroup(groupId: number): Observable<CustomPlusValueGroup> {
    return this.http.get<CustomPlusValueGroup>(`${this.apiUrl}/agents/plus-value/group/custom/get/${groupId}`);
  }

  getCustomPlusValueGroupByAgentId(agentId: number): Observable<CustomPlusValueGroup> {
    return this.http.get<CustomPlusValueGroup>(`${this.apiUrl}/agents/plus-value/group/custom/getByAgentId/${agentId}`);
  }

  getCustomPlusValueGroups(officeId: number): Observable<CustomPlusValueGroup[]> {
    return this.http.get<CustomPlusValueGroup[]>(`${this.apiUrl}/agents/plus-value/group/custom/get/groups/${officeId}`);
  }

  getCustomPlusValueGroupAgents(groupId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/agents/plus-value/group/custom/get/agents/${groupId}`);
  }

  getAgentsAvailableForCustomGroup(officeId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/agents/plus-value/group/custom/get/available-agents/${officeId}`);
  }

  createDefaultPlusValueGroup(data: DefaultPlusValueGroup): Observable<DefaultPlusValueGroup> {
    return this.http.post<DefaultPlusValueGroup>(`${this.apiUrl}/agents/plus-value/group/default/create`, data);
  }

  updateDefaultPlusValueGroup(data: DefaultPlusValueGroup): Observable<DefaultPlusValueGroup> {
    return this.http.put<DefaultPlusValueGroup>(`${this.apiUrl}/agents/plus-value/group/default/update`, data);
  }

  createCustomPlusValueGroup(data: CustomPlusValueGroup): Observable<CustomPlusValueGroup> {
    return this.http.post<CustomPlusValueGroup>(`${this.apiUrl}/agents/plus-value/group/custom/create`, data);
  }

  updateCustomPlusValueGroup(data: CustomPlusValueGroup): Observable<CustomPlusValueGroup> {
    return this.http.put<CustomPlusValueGroup>(`${this.apiUrl}/agents/plus-value/group/custom/update`, data);
  }

  deleteCustomPlusValueGroup(groupId: number): Observable<HttpStatusCode> {
    return this.http.delete<HttpStatusCode>(`${this.apiUrl}/agents/plus-value/group/custom/delete/${groupId}`);
  }

  //end region PlusValueGroups

  saveBusinessName(agentId: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/agents/${agentId}/saveBusinessName`, data);
  }

  saveBrandingTheme(agentId: number, isCustomTheme: boolean): Observable<any> {
    let url: string = `${this.apiUrl}/agents/${agentId}/saveBrandingTheme?isCustomTheme=${String(isCustomTheme)}`;
    return this.http.put<any>(url, {});
  }

  getColorScheme(agentId: number): Observable<IQuoteColorPalette> {
    return this.http.get<IQuoteColorPalette>(`${this.apiUrl}/agents/${agentId}/colorScheme`);
  }

  saveColorScheme(agentId: number, colorScheme: IQuoteColorPalette): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/agents/${agentId}/colorScheme`, colorScheme);
  }

  //agent roles
  //returns different roles list for admin/manager
  getOfficeMgmtAssignableRoles(): Observable<any> {
    let url = `${this.apiUrl}/roles?usage=office-mgmt`;
    return this.http.get<any>(url);
  }

  updateOfficeMgmtAgentRoles(agents): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/office-management/agents`, agents);
  }

  saveBusinessNameEditAllowed(officeId: number, data: any) {
    let url = `${this.apiUrl}/offices/${officeId}/business-name-edit-allowed`;
    return this.http.put<any>(url, data);
  }

  saveBusinessSocialMediaForAgent(agentId: number, profileDto: AgentBusinessProfile): Observable<AgentBusinessProfile> {
    return this.http.put<AgentBusinessProfile>(`${this.apiUrl}/agents/${agentId}/saveSocialMedia`, profileDto)
  }
}
