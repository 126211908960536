import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Third Party Libraries
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Modules
import { SharedModule } from '../shared/shared.module';

// Pages
import { LoginPageComponent } from './pages/login/login.page.component';

// Components
import { LoginComponent } from './components/login/login.component';
import { LoginModalComponent } from './modals/login/login.modal.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

/**
 * Responsible for ADX auth and login functionality.
 * */
@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        SharedModule
    ],
    declarations: [
        LoginPageComponent,
        LoginComponent,
        LoginModalComponent,
        ChangePasswordComponent
    ],
    exports: [
        LoginPageComponent,
        LoginComponent,
        LoginModalComponent
    ]
})
export class LoginModule {

}
