import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { LoginApiService } from '../../services/login-api.service';
import { validate } from '../../../shared/validators/validate';
import { IAdxRequestErrorContext } from '../../../core/models/adx-request-error-context';
import { AgentCookieService } from '../../../agent/services/agent-cookie.service';
import { ServerVersionConfiguration } from '../../../core/models/server-version-configuration.model';
import { CoreApiService } from '../../../core/services/core-api.service';

/**
 * Permits an agent to log in, accept terms and conditions or have their password reset.
 * */
@Component({
    selector: 'adx-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    @Output() login: EventEmitter<any> = new EventEmitter<any>();

    @Input() state: 'login' | 'reset' | 'terms' | 'updated-terms' | 'password-sent' = 'login';
    @Output() stateChange: EventEmitter<string> = new EventEmitter<string>();

    loading = false;

    loginForm = new UntypedFormGroup({
        Email: new UntypedFormControl('', [Validators.required]),
        Password: new UntypedFormControl('', [Validators.required])
    });

    resetPasswordForm = new UntypedFormGroup({
        Email: new UntypedFormControl('', [Validators.required])
    });

    error: any | null = null;

    updatedTermsAccepted: boolean = false;

    serverConfiguration: ServerVersionConfiguration;

    constructor(private loginApi: LoginApiService, private agentCookieService: AgentCookieService, private coreApiService: CoreApiService) {
        this.coreApiService.getServerVersion().subscribe(resp => this.serverConfiguration = resp, (resp: IAdxRequestErrorContext) => { resp.silent = true });
    }

    onForgotPasswordLink(): void {
        this.state = 'reset';
        this.stateChange.emit(this.state);
    }

    onBackToLoginLink(): void {
        this.state = 'login';
        this.stateChange.emit(this.state);
    }

    onReadTermsAndConditions(): void {
        this.state = 'terms';
        this.stateChange.emit(this.state);
    }

    onLoginSubmit(): void {
        if (!validate(this.loginForm)) {
            return;
        }

        const dto = {
            email: this.loginForm.get('Email').value as string,
            password: this.loginForm.get('Password').value as string
        };

        this.loading = true;
        this.error = null;

        this.loginApi.login(dto).subscribe(resp => this.handleLoginSuccessResponse(resp), resp => this.handleLoginFailureResponse(resp));
    }

    onAcceptTermsAndConditions(): void {
        this.loading = true;

        this.loginApi.acceptTermsAndConditions().subscribe(() => this.login.emit(), resp => this.handleLoginFailureResponse(resp));
    }

    onResetPasswordSubmit(): void {
        if (!validate(this.resetPasswordForm)) {
            return;
        }

        this.error = null;
        this.loading = true;

        const dto = { email: this.resetPasswordForm.get('Email').value as string };

        this.loginApi.resetPassword(dto).subscribe(() => {
            this.state = 'password-sent';
            this.stateChange.emit(this.state);
            this.loading = false;
        }, resp => this.handleLoginFailureResponse(resp));
    }

    handleLoginSuccessResponse(response: any): void {
        this.agentCookieService.removeSelectedAgentsCookie();
        if (!response.Body.TCAccepted) {
            this.state = 'updated-terms';
            this.stateChange.emit(this.state);
            this.loading = false;
        } else {
            this.login.emit();
        }
    }

    handleLoginFailureResponse(response: IAdxRequestErrorContext): void {
        if (response.error && response.error.error) {
            this.error = response.error.error;
            response.silent = true;
        }

        this.loading = false;
    }
}
