import { Component } from '@angular/core';

/**
 * Empty layout component, useful for login page and other pages where
 * we don't want to display any headers or footers.
 * */
@Component({
    selector: 'adx-default-layout',
    template: '<router-outlet></router-outlet>'
})
export class DefaultLayoutComponent {

}
