<adx-notice *ngIf="error" [noticeType]="'warning'" [invertColors]="true">
    <div style="font-size: 14px;">
        {{ error.UserDisplayMessage }} (Ref: {{ error.ErrorMessageId }})
    </div>
</adx-notice>

<ng-container *ngIf="state === 'login'">
    <form class="form" [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
        <div class="form-group">
            <label>Email Address</label>
            <input type="email" class="form-control" formControlName="Email" adxValidationTooltip />
        </div>
        <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control" formControlName="Password" adxValidationTooltip />
        </div>

        <button type="submit" class="btn btn-primary" [disabled]="loading"><i class="fas fa-spinner fa-spin" *ngIf="loading"></i> Sign in</button>

        <div class="row" style="margin-top: 10px;">
            <div class="col-6">
                <button class="btn btn-link" (click)="onForgotPasswordLink()">Forgot your password?</button>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn-link" (click)="onReadTermsAndConditions()">Read Terms and Conditions</button>
            </div>
        </div>
    </form>

    <div style="text-align: center;margin-top: 10px;margin-bottom: 1px;">
      <img src="https://media.kensingtontours.com/image/upload/q_auto,h_30/v1615798990/kt/live/static/logos/Trip-Arc-PowerBy-Horizontal-White.png" style="text-align:center;display: inline;height: 22px;">
    </div>
</ng-container>

<ng-container *ngIf="state === 'reset'">
    <form class="form" [formGroup]="resetPasswordForm" (ngSubmit)="onResetPasswordSubmit()">
        <div class="form-group">
            <label>Email Address</label>
            <input type="email" class="form-control" formControlName="Email" />
        </div>

        <button type="submit" class="btn btn-primary" [disabled]="loading">Submit Password Reset</button>

        <div class="row" style="margin-top: 10px;">
            <div class="col-8">
                <a href="mailto:{{serverConfiguration.ADXSupportEmail}}?Subject=Change Password">Need help? Contact ADX Support</a>
            </div>
            <div class="col-4 text-right">
                <button class="btn btn-link" (click)="onBackToLoginLink()">Back to Login</button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="state === 'password-sent'">
    <p>We’ve sent password reset instructions to your email address.</p>
    <p>If you don’t receive instructions within a few minutes, check your email’s spam and junk filters.</p>

    <a href="mailto:{{serverConfiguration.ADXSupportEmail}}?Subject=Change Password">Help! I haven’t received the reset email.</a>
</ng-container>

<ng-container *ngIf="state === 'terms' || state === 'updated-terms'">
    <div class="card card-body bg-light terms-card">
        <h3 class="login-tc-title">Terms and Conditions</h3>
        <strong>General Terms and Conditions</strong>
        <p>
            These General Terms and Conditions (the “General Terms”) describes the terms and conditions applicable
            to your use of the TripArc’s on-line booking tool (“ADX”), which provides you with functionality to: (i)
            search, secure quotes and book reservations for certain travel products and services (a “Booking”); (ii)
            provide certain travel information (including quotes, reservation information and client invoices in regard
            to a Booking); (iii) identify commissions payable to you in regard to travel products and services (“travel
            products”) that you may book for your client, and (iv) other services and functions relating to participating
            Vendors that TripArc may elect to add or modify from time to time. The combination of the ADX and the travel
            products available through the use of the ADX is referred to collectively as the “TripArc Services”.
            The TripArc Services are made available to you by TripArc Inc., as well as certain Vendors.
        </p>
        <ol class="tc-list">
            <li>
                <strong>Definitions</strong>.
                <ol>
                    <li>“ADX” has the meaning given to it in the first paragraph of these General Terms.</li>
                    <li>
                        “Affiliate” means, with respect to any Person, any other Person directly or indirectly controlling,
                        controlled by, or under common control with the first Person.
                    </li>
                    <li>
                        “Authorized Users” means employees and independent travel advisors under your control who
                        utilize the TripArc Services as part of your business consistent with these General Terms.
                    </li>
                    <li>
                        “Automated Device” has the meaning given to it in Section 5.
                    </li>
                    <li>
                        “Booking” has the meaning given to it in the first paragraph of these General Terms.
                    </li>
                    <li>
                        “Confidential Information” means the terms and conditions of these General Terms, any and all
                        applicable intellectual property rights, proprietary and confidential information of TripArc or you,
                        and each their Affiliates, subsidiaries, successors or assigns concerning their past, present or
                        future industrial, corporate, and trade secrets, research, development, business activities or
                        affairs, finances, properties, methods of operation, processes and systems, agreements (including
                        without limitation private fare or special discount agreements) related to your business or the
                        business of TripArc disclosed under these General Terms. Confidential Information does not
                        include any information that is or becomes generally known to the public except through a breach
                        of an obligation of confidence, (2) which was in the receiving party’s possession or was known by
                        it prior to receipt by the disclosing party, (3) was rightfully disclosed to the receiving party without
                        restriction, or (4) was independently developed by a party without the use of the other party’s
                        Confidential Information
                    </li>
                    <li>
                        “General Terms” has the meaning given to it in the first paragraph of these General Terms.
                    </li>
                    <li>
                        “Person” means an individual, a corporation, a partnership, a limited liability company, an
                        association, a trust, a joint stock company, a joint venture, an unincorporated organization, any
                        governmental authority, or other entity or organization.
                    </li>
                    <li>
                        "Sublicense Agreement” means the agreement, which may include a membership agreement, as
                        applicable) between you and a licensee of the TripArc Services pursuant to which you have been
                        granted a sublicense to use the TripArc Services.
                    </li>
                    <li>
                        “TripArc” means TripArc Inc.
                    </li>
                    <li>
                        “TripArc Services” means ADX and the related services delivered by TripArc to you under these
                        General Terms.
                    </li>
                    <li>
                        “Vendor” means any vendor of travel related products, information or services which has an
                        agreement with TripArc or an Affiliate of TripArc for the display of information regarding its
                        products or services on the TripArc Services.
                    </li>
                </ol>
            </li>
            <li>
                <strong>TripArc Services.</strong> You hereby represent and warrant that you have sublicensed a personal, limited,
                nonexclusive, non-transferable, royalty free license to access and use the TripArc Services pursuant
                to the Sublicense Agreement. You further represents and warrant that, when using the TripArc
                Services, you will (a) comply with the terms of these General Terms, as it may be amended from time
                to time; (b) be responsible for any equipment and software necessary to access the TripArc Services;
                (c) use the TripArc Services only in connection with legitimate transactions and without limiting the
                generality of the foregoing, you shall not use the TripArc Services for: (i) speculative booking; (ii)
                improper creation or modification of records; (iii) reservation of space in anticipation of demand; (iv)
                transmission of personal messages other than by an electronic mail system; (v) disclosure or access
                to the TripArc Services (which are not expressly permitted to be disclosed or accessed under these
                General Terms) to persons other than your employees and consultants who have signed non-disclosure
                agreements covering the TripArc Services; (vi) publishing any commissions identified on the
                TripArc Services; (vii) assessing the TripArc Services through third party products that are not expressly
                authorized by TripArc; (d) immediately inform TripArc of any occurrence or circumstance that arises
                which could reasonably lead to a claim being asserted against TripArc; and (e) comply with all other
                laws applicable to your use of the TripArc Services.
            </li>
            <li>
                <strong>TripArc Services Modifications.</strong> TripArc reserves the right, for any reason, in its sole discretion and at
                any time, to terminate, change, upgrade, or suspend any aspect of the TripArc Services, including but not
                limited to content, features, functions, methods of access, or hours of availability. TripArc may
                impose limits on certain features of the TripArc Services or restrict your access to part or all of the
                TripArc Services without notice or penalty.
            </li>
            <li>
                <strong>Authorized Users.</strong> The TripArc Services may only be accessed by Authorized Users. You are
                responsible for any use of the TripArc Services by your Authorized Users or any third-party using your
                access. TripArc will terminate use of the TripArc Services by any Person who either TripArc reasonably
                believes has utilized the TripArc Services for an unauthorized or improper use.
            </li>
            <li>
                <strong>Restrictions on Use.</strong> You will not use any robot, spider, or other automated device (“Automated
                Device”) to access or use the TripArc Services in any way. You will not copy, reproduce, alter, modify,
                create derivative works from, or publicly display any content from the TripArc Services, or otherwise
                use the TripArc Services in any way not expressly authorized by these General Terms, without the
                prior express written permission of TripArc.  You will not reverse engineer, decompile, disassemble,
                decrypt, extract portions of, or translate the TripArc Services, except as permitted by applicable law.
                You agree not to “frame” or “mirror” any content or information contained on or accessible from the
                TripArc Services on any other server or Internet-based device without the prior written authorization
                of TripArc.  You further warrant and agree that, while using the TripArc Services, you will not upload,
                post, transmit, publish, or otherwise distribute through the TripArc Services any materials other than
                those materials that are generated by the TripArc Services for use by the you or its client, and you will
                not commit any acts that: (a) are unlawful, threatening, harassing, obscene, or profane, (b) restrict or
                inhibit any other user from using and enjoying the TripArc Services, (c) commit or encourage conduct
                that would constitute a criminal offense or give rise to civil liability, or (d) contain a virus or other
                harmful components, advertising of any kind not approved by TripArc, or false or misleading
                indications of origin or statements of fact.  You also warrant and agree that, while using the TripArc
                Services, you will not post or transmit (i) any bulk e-mails, advertisements, solicitations, offers for sale
                of goods or services, chain letters, pyramid schemes, investment opportunities or schemes, or other
                unsolicited commercial communications (except as otherwise expressly permitted herein), or (ii)
                engage in email spamming or flooding.
            </li>
            <li>
                <strong>Content and Inventory.</strong> The TripArc Services provided under these General Terms will enable you to
                locate and transact Vendor travel product and services. The Vendor content and inventory
                information available through the TripArc Services will not be verified or confirmed by TripArc, and
                ALL SUCH INFORMATION IS PROVIDED “AS IS” AND WITHOUT WARRANTY OF ANY KIND. As between
                you and TripArc, TripArc will have sole control over the management of the TripArc Services.
            </li>
            <li>
                <strong>Proprietary Rights.</strong> TripArc and the Vendors retain all right, title, and interest in and to their respective
                portions of the TripArc Services and any enhancements thereto, including without limitation graphical
                designs, names, icons, interfaces, and other design elements (i.e., the selection and arrangement of
                materials therein and the “look and feel” thereof).
            </li>
            <li>
                <strong>Bookings made through the Services.</strong> TripArc’s sole responsibility with respect to the TripArc Services
                is to enable you to access the travel product inventory made available through the TripArc Services
                and to generate quotes, commission rates (when available), and Bookings.
            </li>
            <li>
                <strong>Fees, Payments, and Taxes.</strong> Any fee, commission or compensation payable to the you as the result
                of a Booking, and any taxes payable by you relating thereto shall be determined and paid to you in
                accordance with your Sublicense Agreement. TripArc assumes no responsibility for payment to you of
                any fees or commissions.
            </li>
            <li>
                <strong>Change and Cancellation Fees.</strong> If your client cancels or modifies services booked by you, applicable
                Vendors may also charge a change or cancellation fee as set forth in the Vendors’ terms and
                conditions, for which you and your client are responsible.
            </li>
            <li>
                <strong>Termination.</strong> If you breach any term or condition of these General Terms or you Sublicence
                Agreement, or if TripArc believes that your actions may cause legal liability for you, TripArc or the
                users of the TripArc Services, TripArc will promptly give you notice, and may temporarily or indefinitely
                suspend your ability to use the TripArc Service, and/or terminate these General Terms. Whatever
                actions TripArc decides to take will not limit TripArc in pursuing any other legal or equitable remedies
                available to it. Upon termination of these General Terms for any reason, you will cease access and use
                of the TripArc Services. Otherwise, either party may terminate these General Terms upon ten (10)
                days’ notice to the other party.
            </li>
            <li>
                <strong>Confidentiality.</strong> You acknowledge that by reason of your relationship with TripArc under these
                General Terms, you will have access to TripArc’s Confidential Information.  You agree to maintain in
                confidence all Confidential Information received from TripArc, both oral and written, and agree not
                to disclose or otherwise make available such Confidential Information to any third party without the
                prior written consent of the TripArc.  You agree to use the Confidential Information only for the
                purpose of performing these General Terms.  You acknowledge and agrees that, subject to applicable
                law, TripArc may use and distribute aggregate statistical and marketing information from which the
                identity of you or your customers cannot be determined through the use of reasonable efforts. For
                purposes of these General Terms, “Confidential Information” shall mean ADX and those TripArc
                Services which are not expressly permitted to be disclosed under these General Terms and all
                information and materials concerning TripArc’s business, plans, pricing, technology, data and
                products that are confidential and of substantial value to TripArc, which value would be impaired if
                such information and materials were disclosed to third parties. For purposes of these General Terms
                all information and materials relating to the technology utilized in the TripArc Services shall be
                deemed Confidential Information. This Section 11 shall survive the expiration or termination of these
                General Terms.
            </li>
            <li>
                <strong>BSP/ARC Credentials and Background Check.</strong> You authorize TripArc to check your credentials and
                history with ARC (Airline Reporting Commission) or BSP, as applicable. Your agrees to TripArc’s
                engaging a third party to conduct background screening services and to provide authorizations from
                current and future workers associated with your business and you agree that you shall execute all
                documents and provide all information necessary for the purpose of conducting the background
                check.
            </li>
            <li>
                <strong>DISCLAIMER OF ALL OTHER WARRANTIES.</strong> YOUR USE OF THE TRIPARC SERVICES IS AT YOUR SOLE
                RISK. THE TRIPARC SERVICES ARE PROVIDED ON AN “AS IS” BASIS. NEITHER TRIPARC NOR ITS
                VENDORS MAKE ANY WARRANTIES WITH RESPECT TO THE SERVICES PROVIDED HEREUNDER, AND
                EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO WARRANTIES THAT: (I)
                THE TRIPARC SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE, OR VIRUS FREE, (II)
                THE TRIPARC SERVICES WILL MEET YOUR REQUIREMENTS, (III) THE RESULTS THAT MAY BE OBTAINED
                FROM THE USE OF THE TRIPARC SERVICES ARE ACCURATE OR RELIABLE, AND (IV) ANY ERRORS IN THE
                TRIPARC SERVICES WILL BE CORRECTED. TRIPARC ALSO EXPRESSLY DISCLAIMS ALL WARRANTIES AND
                CONDITIONS, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES AND CONDITIONS OF
                MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSES, PERFORMANCE, TITLE AND NON-INFRINGEMENT
                OF INTELLECTUAL PROPERTY RIGHTS, QUIET ENJOYMENT, SYSTEM INTEGRATION,
                DATE ACCURACY, AND ANY WARRANTIES OR CONDITIONS ARISING BY STATUTE OR OTHERWISE IN
                LAW, EQUITY, OR FROM A COURSE OF DEALING OR USAGE OF TRADE.  CLIENT MAY HAVE OTHER
                LEGAL RIGHTS RELATING TO THESE GENERAL TERMS, WHICH MAY VARY FROM STATE/JURISDICTION
                TO STATE/JURISDICTION.
            </li>
            <li>
                <strong>LIMITATION OF LIABILITY.</strong> IN NO EVENT SHALL TRIPARC OR ITS VENDORS BE LIABLE TO YOU OR ANY
                OTHER PERSON UNDER ANY LEGAL THEORY OF CONTRACT, NEGLIGENCE, STRICT LIABILITY, PRODUCT
                LIABILITY, TORT, OR OTHERWISE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
                CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING BUT NOT LIMITED TO LOSS OF BUSINESS,
                SALES, REVENUE, USE, DATA, HARDWARE, SOFTWARE, OR GOODWILL; INTERRUPTION OF BUSINESS;
                ANTICIPATED OR LOST PROFITS; OR THIRD PARTY CLAIMS) THAT ARISE OUT OF OR IN CONNECTION
                WITH YOUR USE OF OR INABLITY TO USE THE TRIPARC SERVICES. TRIPARC’S TOTAL AGGREGATE
                LIABILITY TO YOU OR ANY OTHER PERSON FOR ACTUAL, DIRECT DAMAGES ARISING OUT OF OR IN
                ANY WAY CONNECTED WITH THESE GENERAL TERMS WILL NOT EXCEED ONE HUNDRED DOLLARS
                ($100) AND YOU AGREES TO BE RESPONSIBLE FOR ANY LIABILITY EXCEEDING SUCH AMOUNT.  YOU
                ACKNOWLEDGES THAT THIS LIMITATION OF LIABILITY WILL APPLY WHETHER OR NOT TRIPARC IS
                GIVEN NOTICE OF THE POSSIBLITY OF SUCH DAMAGES.
            </li>
            <li>
                <strong>Assignment.</strong> You may not assign these General Terms or any of your rights or obligations under it
                without TripArc’s prior written consent. Any attempted assignment without such consent will be void
                and will cause these General Terms and your right to use the TripArc Services to terminate
                immediately and without notice. These General Terms and the rights and obligations of TripArc and
                you under it will be binding on TripArc’s successors and assigns, and your heirs, as well as your
                successors and assigns that TripArc authorizes with prior written consent.
            </li>
            <li>
                <strong>Relationship.</strong> You have only the rights set forth in these General Terms to use the TripArc Services.
                These General Terms create no relationship between you and TripArc, other than as expressly stated
                herein. Specifically, these General Terms creates no relationship between you and TripArc as
                independent contractors, partners, joint ventures, fiduciaries, agents or employees.
            </li>
            <li>
                <strong>Responsibility for Other Parties.</strong> TripArc is not responsible for the travel products made available
                through the TripArc Services, it being understood that the Vendors thereof are solely responsible for
                fulfillment, customer service and all other issues that may arise relating thereto. For the purposes of
                this provision, IF A PASSENGER USING A CONFIRMED RESERVATION MADE BY YOU THROUGH THE
                TRIPARC SERVICES IS REFUSED SERVICE BY THE VENDOR DUE TO AN OVERSALE OR THE LACK OF
                RECORD OF SUCH RESERVATION OR OTHERWISE, YOUR SOLE REMEDY WILL BE THAT PROVIDED FOR
                BY SUCH VENDOR IN ITS TARIFF OR THE TERMS AND CONDITIONS OF THE VENDOR’S CONTRACT
                APPLICABLE TO YOU AND/OR THE PASSENGER.
            </li>
            <li>
                <strong>Waiver.</strong> TripArc’s waiver of any particular breach or of any particular act or omission of
                noncompliance with any requirement of these General Terms shall not constitute a waiver of any
                other breach or act or omission of noncompliance with any requirement of these General Terms.
            </li>
            <li>
                <strong>Governing Law/Arbitration.</strong> To the extent not expressly prohibited by local law, these General Terms
                and the performance of the obligations herein shall be governed by the laws of the State of Delaware,
                United States of America, without regard to its conflict of laws provisions, and by applicable federal
                laws of the United States. In the unlikely event of a dispute or claim between you and TripArc, its
                employees or agents, having anything to do with your relationship with TripArc or the interpretation
                or enforcement of these General Terms, you and TripArc mutually agree that all such claims will be
                settled by binding arbitration. As a result, you and TripArc give up the right to have the dispute decided
                in civil court by a jury. Instead, it will be resolved by an impartial arbitrator whose decision will be
                final, without right of appeal. The arbitration will be held in Wilmington, Delaware. The parties will be
                permitted to engage in reasonable discovery prior to the arbitration. Following a hearing conducted
                by a single arbitrator, the arbitrator shall issue a signed and dated written opinion which shall decide
                all issues submitted. The arbitrator shall award only those remedies which are authorized by law and
                requested by the parties, and which the arbitrator determines to be supported by credible, relevant
                evidence. Unless otherwise provided by law, the parties will be responsible for their own attorney’s
                fees and expenses. The cost of the arbitrator will be shared equally between you and TripArc. To the
                extent allowed by applicable law, any claim or cause of action arising from or relating to your access
                or use of the TripArc Services must be commenced by way of arbitration as set forth above within two
                (2) years after the date on which such claim or action arose or accrued.
            </li>
            <li>
                <strong>Entire Agreement.</strong> These General Terms constitutes the entire agreement between TripArc and you
                with respect to the TripArc Services and supersedes all prior agreements between TripArc and you.
                No oral statements, promises, or agreements will alter the terms of these General Terms. If any
                provision of these General Terms is held unenforceable, the unenforceable provision will, at TripArc’s
                option, be severed from these General Terms, or construed in accordance with applicable law as
                nearly as possible to reflect TripArc’s and your mutual original intent, and all other provisions will
                remain in full force and effect. However, if in TripArc’s opinion such severance or construction of such
                provision would frustrate the purpose of these General Terms, then TripArc may terminate these
                General Terms. These General Terms shall also inure to the benefit of TripArc’s Vendors of the TripArc
                Services, who shall be entitled to enforce the terms and conditions of these General Terms against
                you as a third-party beneficiary. These General Terms are in addition to any Sublicense Agreement
                and does not in any way amend, alter, supersede, or otherwise effect the provisions of the Sublicense
                Agreement including the commissions payable thereunder to the you or the obligations of the you
                under the Sublicense Agreement.
            </li>
            <li>
                <strong>Miscellaneous.</strong>
                <ol>
                    <li>
                        <span class="tc-sub">Modification of Agreement.</span> These General Terms may be modified only by TripArc, at any time
                        without notice. These General Terms may be accessed via the TripArc Services. Any such
                        modifications shall become effective immediately upon posting. By logging in, accessing, and/or
                        using the TripArc Services, you agree to review these General Terms periodically to learn of and
                        be in compliance with, any modified terms and conditions.
                    </li>
                    <li>
                        <span class="tc-sub">Acceptance of Agreement.</span> BY SELECTING OR CLICKING THE “I AGREE” BUTTON BELOW, OR BY
                        ACCESSING OR USING THE TRIPARC SERVICES, YOU ACKNOWLEDGE AND AGREE THAT (1) YOU OR
                        YOUR AUTHORIZED REPRESENTATIVE HAVE READ AND UNDERSTAND THESE GENERAL TERMS, (2)
                        YOU ACCEPTS THESE GENERAL TERMS IN ITS PRESENT FORM AND AS IT MAY BE MODIFIED BY
                        TRIPARC FROM TIME TO TIME, (3) YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS
                        CONTAINED HEREIN, AS WELL AS BY THE TERMS AND CONDITIONS OF ANY MODIFIED VERSION(S)
                        OF THESE GENERAL TERMS, AND (4) YOU ACCEPT THESE GENERAL TERMS ON YOUR OWN BEHALF
                        AND ON BEHALF OF YOUR AUTHORIZED USERS, AND WILL ASSURE THAT THE YOU AND YOUR
                        AUTHORIZED USERS WILL COMPLY WITH THESE GENERAL TERMS. AFTER CLICKING THE “I AGREE”
                        BUTTON BELOW, YOU CAN REGISTER TO USE THE SERVICES. THE SERVICES CAN THEN BE
                        ACCESSED AND YOU MAY USE THE SERVICES, SUBJECT TO THESE GENERAL TERMS.
                    </li>
                    <li>
                        <span class="tc-sub">Refusal of Agreement.</span> YOU DO NOT AGREE TO THESE GENERAL TERMS IF YOU CLOSE THE
                        GENERAL TERMS BOX WITHOUT SELECTING, ‘I ACCEPT’. IN DOING SO, YOU ARE NOT AUTHORIZED
                        TO ACCESS OR USE THE SERVICES.
                    </li>
                </ol>
            </li>
        </ol>
    </div>

    <div *ngIf="state === 'terms'">
        <button class="btn btn-link" (click)="onBackToLoginLink()">Back to Login</button>
    </div>

    <div *ngIf="state === 'updated-terms'">
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="Login_Checkbox_AcceptNewTerms" [disabled]="loading" [(ngModel)]="updatedTermsAccepted" />
            <label class="form-check-label" for="Login_Checkbox_AcceptNewTerms">I have read and accepted the terms and conditions</label>
        </div>

        <button class="btn btn-primary" (click)="onAcceptTermsAndConditions()" [disabled]="loading || !updatedTermsAccepted">Continue</button>
    </div>
</ng-container>
