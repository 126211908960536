import { IApiDeserializable } from './api-deserializable';

/**
 * Contains the basic information about how the server is configured, paths and version information.
 * */
export class ServerVersionConfiguration implements IApiDeserializable {
    ReleaseVersion: string;

    BuildVersion: string;

    Machine: string;

    Environment: string;

    BuildConfig: string;

    AppName: string;

    SHA: string;

    Database: string;

    ClientSiteUrl: string;

    AffiliateClientSiteUrl: string;

    ADXSupportEmail: string;

    CurrentDateTime: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
