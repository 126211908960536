import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import {
  NotificationsModule as NexNotificationsModule
} from "@triparc/nexus";
import {
  ButtonsModule,
  CardsModule,
  DropdownModule,
  ExpansionPanelModule,
  InputsModule, TabsModule
} from "@triparc/nexus";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";


// Components
import { TimeComponent } from './form-controls/time/time.form-control.component';
import { PanelComponent } from './components/panel/panel.component';
import { NoticeComponent } from './components/notice/notice.component';
import { TileComponent } from './components/tile/tile.component';
import { AddressPipe } from './pipes/address.pipe';

import { MoneyPipe } from './pipes/money.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { MessageOverlayComponent } from './components/message-overlay/message-overlay.component';
import { ConfirmationOverlayComponent } from './components/confirmation-overlay/confirmation-overlay.component';
import { RequestSupportModalComponent } from './modals/request-support/request-support.modal.component';
import { ValidationTooltipDirective } from './directives/validation-tooltip.directive';
import { CombodateFormControlComponent } from './form-controls/combodate/combodate.form-control.component';
import { CombodateDSFormControlComponent } from './form-controls/combodate-ds/combodate-ds.form-control.component';
import { LoadingPlaceholderComponent } from './components/loading-placeholder/loading-placeholder.component';
import { LocationAutocompleteFormControlComponent } from './form-controls/location-autocomplete/location-autocomplete.form-control.component';
import { CountrypickerFormControlComponent } from './form-controls/countrypicker/countrypicker.component';
import { DurationPipe } from './pipes/duration.pipe';
import { CommissionPipe } from './pipes/commission.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { CombotimeAmPmFormControlComponent } from './form-controls/combotimeampm/combotimeampm.form-control.component';
import { AdxInputGroupDirective } from './directives/adx-input-group.directive';
import { PrettyNamePipe } from './pipes/pretty-name.pipe';
import { DatepickerFormControlComponent } from './form-controls/datepicker/datepicker.form-control.component';
import { DsDatepickerFormControlComponent } from './form-controls/ds-datepicker/ds-datepicker.form-control.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DsFileUploadComponent } from './components/ds-file-upload/ds-file-upload.component';
import { FileExtensionImagePipe } from './pipes/file-extension-image.pipe';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { FormatCurrencyPipe } from './pipes/format-currency.pipe';
import { FormatKbPipe } from './pipes/format-kb.pipe';
import { FormatMsPipe } from './pipes/format-ms.pipe';
import { FormatTravelServiceIconClassPipe } from './pipes/format-travel-service-icon-class.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { LineBreakPipe } from './pipes/line-break.pipe';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { DatepickerDayComponent } from './components/datepicker-day/datepicker-day.component';
import { PanelLiteComponent } from './components/panel-lite/panel-lite.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { FormErrorMessageService } from './services/form-error-message.service';
import { CardComponent } from './components/card/card.component';
import { ModalPanelComponent } from './components/modal-panel/modal-panel.component';
import { CheckboxInputComponent } from './components/checkbox-input/checkbox-input.component';
import { DoubleClickDirective } from './directives/double-click.directive';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { OverlaySpinnerComponent } from './components/overlay-spinner/overlay-spinner.component';
import { ProgressTableComponent } from './components/progress-table/progress-table.component';
import { DistancePipe } from './pipes/distance.pipe';
import { DsInfoboxComponent } from './components/ds-infobox/ds-infobox.component';
import { DropdownFormControlComponent } from './form-controls/dropdown-ds/dropdown-ds.form-control.component';
import { ComboGenderFormControlComponent } from './form-controls/combogender-ds/combogender-ds.form-control.component';
import { ComboRelationshipFormControlComponent } from './form-controls/comborelationship-ds/comborelationship-ds.form-control.component';
import { LoadingModalDsComponent } from './components/loading-modal-ds/loading-modal-ds.component';
import { GridTableComponent } from './components/grid-table-ds/grid-table-ds.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DropdownInputDsComponent } from './form-controls/dropdown-input-ds/dropdown-input-ds.component';
import { DsSnackBarsContainerComponent } from './components/ds-snack-bar/ds-snack-bars-container.component';
import { DsPopupCardContainerComponent } from './components/ds-popup-card-container/ds-popup-card-container.component';
import { DsSearchDropdownComponent } from './components/ds-search-dropdown/ds-search-dropdown.component';
import { DsInputComponent } from './form-controls/ds-input/ds-input.component';
import { DsChipsComponent } from './components/ds-chips/ds-chips.component';
import { DsSwitchComponent } from './form-controls/ds-switch/ds-switch.component';
import { DsDropdownComponent } from './components/ds-dropdown/ds-dropdown.component'
import { DsDropdownMenuComponent } from './form-controls/ds-dropdown/ds-dropdown.component';
import { DsGridLoadingComponent } from './components/ds-grid-loading/ds-grid-loading.component';
import { DsInlineEditComponent } from './components/ds-inline-edit/ds-inline-edit.component';
import { DsDescriptionComponent } from './components/ds-description/ds-description.component';
import { SortableHeaderDirective } from './directives/sortable-header.directive';
import { DsTabComponent, DsTabTitleDirective } from './components/ds-tabs/ds-tab.component';
import { DsTabsComponent } from './components/ds-tabs/ds-tabs.component';
import { FileImagePreviewDirective } from './directives/file-image-preview.directive';
import { FileDragAndDropDirective } from './directives/file-drag-and-drop.directive';
import { DsColorPickerComponent } from './form-controls/ds-color-picker/ds-color-picker.component';
import { DsDndImageUploadComponent } from './components/ds-dnd-image-upload/ds-dnd-image-upload.component';
import { DsDndFileComponent } from './components/ds-dnd-file/ds-dnd-file.component';
import { DsConfirmCancelButtonGroupComponent } from './form-controls/ds-confirm-cancel-button-group/ds-confirm-cancel-button-group.component';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import { DsFieldComponent } from './form-controls/ds-field/ds-field.component';
import { FieldInputDirective } from './directives/field-input.directive';
import { DsProfileImageComponent } from './components/ds-profile-image/ds-profile-image.component';
import { DsWidgetComponent } from './components/ds-widget/ds-widget.component';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';
import { DsPanelButtonComponent } from './components/ds-panel-button/ds-panel-button.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { NoteComponent } from './components/note/note.component';
import { QuillModule } from 'ngx-quill';
import { StatementContentComponent } from './components/statement-content/statement-content.component';
import { FooterLegalComponent } from './components/footer/footer-legal.component';
import { SendItineraryFooterComponent } from './components/send-itinerary-footer/send-itinerary-footer.component';
import { SaveSearchCriteriaModalComponent } from './modals/save-search-criteria/save-search-criteria.modal.component';
import { EmailMessageComponent } from './components/email-message/email-message.component';
import { SearchCountryPipe } from './form-controls/ds-tel-input/seacrh-country.pipe';
import { DsTelInputComponent } from './form-controls/ds-tel-input/ds-tel-input.component';
import { TotalTravelersComponent } from './components/total-travelers/total-travelers.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { RoleGuardService } from './services/role-guard.service';
import { DurationComponent } from './components/duration/duration.component';
import { AirportFormControlComponent } from './form-controls/airport/airport.form-control.component';
import { DsSlotComponent } from './components/ds-slot/ds-slot.component';
import { DsTimepickerComponent } from './form-controls/ds-timepicker/ds-timepicker.component';
import { MarketingCarrierFormControlComponent } from './form-controls/marketing-carrier/marketing-carrier.form-control.component';
import {AdminMessageComponent} from "./components/admin-message/admin-message.component";
import {EditableTextDirective} from "./directives/editable-text.directive";
import { DsDndAttachmentUploadComponent } from './components/ds-dnd-attachment-upload/ds-dnd-attachment-upload.component';
import { ModifyAfterPaidComponent } from './modals/modify-after-paid/modify-after-paid.component';
import {MarkupPillsComponent} from "./components/markup-pills/markup-pills.component";
import {PlusValueServiceLineComponent} from "./components/plus-value-service-line/plus-value-service-line.component";
import {DisableControlDirective} from "./directives/disable-control.directive";
import { CurrencyInputDirective } from './directives/currency-input.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgbAlertModule,
    NexNotificationsModule,
    ButtonsModule,
    CardsModule,
    DropdownModule,
    ExpansionPanelModule,
    InputsModule,
    TabsModule,
    MatIconModule,
    MatButtonModule,
    QuillModule.forRoot(),
  ],
  declarations: [
    AttachmentComponent,
    FileUploadComponent,
    DsFileUploadComponent,
    DatepickerFormControlComponent,
    DsDatepickerFormControlComponent,
    DatepickerDayComponent,
    CombodateDSFormControlComponent,
    CombodateFormControlComponent,
    ComboGenderFormControlComponent,
    ComboRelationshipFormControlComponent,
    DropdownFormControlComponent,
    CountrypickerFormControlComponent,
    LocationAutocompleteFormControlComponent,
    BreadcrumbsComponent,
    ValidationTooltipDirective,
    AdxInputGroupDirective,
    LineBreakPipe,
    AddressPipe,
    MoneyPipe,
    FormatCurrencyPipe,
    FormatKbPipe,
    FormatMsPipe,
    DurationPipe,
    CommissionPipe,
    TruncatePipe,
    PrettyNamePipe,
    SortPipe,
    FileExtensionImagePipe,
    DistancePipe,
    SafeHtmlPipe,
    RequestSupportModalComponent,
    MessageOverlayComponent,
    ConfirmationOverlayComponent,
    PanelComponent,
    NoticeComponent,
    TileComponent,
    LoadingPlaceholderComponent,
    CombotimeAmPmFormControlComponent,
    TimeComponent,
    FormatTravelServiceIconClassPipe,
    PaginationComponent,
    SpinnerComponent,
    PanelLiteComponent,
    LoadingModalDsComponent,
    ValidationMessageComponent,
    CardComponent,
    ModalPanelComponent,
    CheckboxInputComponent,
    DoubleClickDirective,
    ConfirmationModalComponent,
    OverlaySpinnerComponent,
    ProgressTableComponent,
    DsInfoboxComponent,
    DropdownInputDsComponent,
    DsSnackBarsContainerComponent,
    DsPopupCardContainerComponent,
    DsSearchDropdownComponent,
    DsInputComponent,
    DsChipsComponent,
    DsSwitchComponent,
    DsDropdownComponent,
    DsDropdownMenuComponent,
    DsGridLoadingComponent,
    DsInlineEditComponent,
    DsDescriptionComponent,
    SortableHeaderDirective,
    DsTabComponent,
    DsTabsComponent,
    DsTabTitleDirective,
    FileImagePreviewDirective,
    FileDragAndDropDirective,
    DsDndFileComponent,
    DsColorPickerComponent,
    DsDndImageUploadComponent,
    DsConfirmCancelButtonGroupComponent,
    DigitOnlyDirective,
    DsProfileImageComponent,
    DsFieldComponent,
    DsWidgetComponent,
    FieldInputDirective,
    CopyToClipboardDirective,
    DsPanelButtonComponent,
    GridTableComponent,
    TotalTravelersComponent,
    InvoiceComponent,
    NoteComponent,
    StatementContentComponent,
    FooterLegalComponent,
    SendItineraryFooterComponent,
    EmailMessageComponent,
    SaveSearchCriteriaModalComponent,
    SearchCountryPipe,
    DsTelInputComponent,
    SortByPipe,
    DurationComponent,
    AirportFormControlComponent,
    DsSlotComponent,
    DsTimepickerComponent,
    EditableTextDirective,
    MarketingCarrierFormControlComponent,
    AdminMessageComponent,
    DsDndAttachmentUploadComponent,
    ModifyAfterPaidComponent,
    MarkupPillsComponent,
    PlusValueServiceLineComponent,
    DisableControlDirective,
    CurrencyInputDirective
  ],
  providers: [
    FormErrorMessageService,
    MoneyPipe,
    RoleGuardService,
    CurrencyPipe
  ],
  exports: [
    NgbModule,
    AttachmentComponent,
    FileUploadComponent,
    DsFileUploadComponent,
    DatepickerFormControlComponent,
    DsDatepickerFormControlComponent,
    DatepickerDayComponent,
    CombodateDSFormControlComponent,
    CombodateFormControlComponent,
    ComboGenderFormControlComponent,
    ComboRelationshipFormControlComponent,
    DropdownFormControlComponent,
    CountrypickerFormControlComponent,
    LocationAutocompleteFormControlComponent,
    ValidationTooltipDirective,
    AdxInputGroupDirective,
    BreadcrumbsComponent,
    LineBreakPipe,
    AddressPipe,
    MoneyPipe,
    FormatCurrencyPipe,
    FormatKbPipe,
    FormatMsPipe,
    DurationPipe,
    CommissionPipe,
    TruncatePipe,
    PrettyNamePipe,
    SortPipe,
    DistancePipe,
    SafeHtmlPipe,
    FileExtensionImagePipe,
    RequestSupportModalComponent,
    MessageOverlayComponent,
    ConfirmationOverlayComponent,
    PanelComponent,
    NoticeComponent,
    TileComponent,
    LoadingPlaceholderComponent,
    CombotimeAmPmFormControlComponent,
    TimeComponent,
    FormatTravelServiceIconClassPipe,
    PaginationComponent,
    SpinnerComponent,
    PanelLiteComponent,
    LoadingModalDsComponent,
    ValidationMessageComponent,
    CardComponent,
    ModalPanelComponent,
    CheckboxInputComponent,
    DoubleClickDirective,
    ConfirmationModalComponent,
    OverlaySpinnerComponent,
    ProgressTableComponent,
    DsInfoboxComponent,
    DropdownInputDsComponent,
    DsSnackBarsContainerComponent,
    DsPopupCardContainerComponent,
    DsSearchDropdownComponent,
    DsInputComponent,
    DsChipsComponent,
    DsSwitchComponent,
    DsDropdownComponent,
    DsDropdownMenuComponent,
    DsGridLoadingComponent,
    DsInlineEditComponent,
    DsDescriptionComponent,
    SortableHeaderDirective,
    DsTabComponent,
    DsTabsComponent,
    DsTabTitleDirective,
    FileImagePreviewDirective,
    FileDragAndDropDirective,
    DsDndFileComponent,
    DsColorPickerComponent,
    DsDndImageUploadComponent,
    DsConfirmCancelButtonGroupComponent,
    DigitOnlyDirective,
    DsProfileImageComponent,
    DsFieldComponent,
    FieldInputDirective,
    DsWidgetComponent,
    CopyToClipboardDirective,
    DsPanelButtonComponent,
    GridTableComponent,
    TotalTravelersComponent,
    InvoiceComponent,
    NoteComponent,
    StatementContentComponent,
    FooterLegalComponent,
    SendItineraryFooterComponent,
    EmailMessageComponent,
    SaveSearchCriteriaModalComponent,
    DsTelInputComponent,
    SortByPipe,
    DurationComponent,
    AirportFormControlComponent,
    DsSlotComponent,
    DsTimepickerComponent,
    EditableTextDirective,
    MarketingCarrierFormControlComponent,
    AdminMessageComponent,
    DsDndAttachmentUploadComponent,
    ModifyAfterPaidComponent,
    MarkupPillsComponent,
    PlusValueServiceLineComponent,
    CurrencyInputDirective
  ]
})
export class SharedModule {

}
