import { Component, Input } from '@angular/core';
import { ServerVersionConfiguration } from '../../models/server-version-configuration.model';

/**
 * Displays the environment information in the upper-right corner of the browser.
 * */
@Component({
    selector: 'adx-environment-ribbon',
    templateUrl: './environment-ribbon.component.html',
    styleUrls: ['./environment-ribbon.component.scss']
})
export class EnvironmentRibbonComponent {
    @Input() serverConfiguration: ServerVersionConfiguration;

    visible = true;

    hideRibbon(): void {
        this.visible = false;
    }
}
