import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { validate } from '../../../shared/validators/validate';

/**
 * Allows the advisor to change their password.
 * */
@Component({
    selector: 'adx-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    @Output() passwordChanged: EventEmitter<any> = new EventEmitter<any>();

    showingPassword: boolean = false;
    loading: boolean = false;
    passwordForm = new UntypedFormGroup({
        Password: new UntypedFormControl('', [Validators.required]),
        ConfirmPassword: new UntypedFormControl('', [Validators.required])
    });

    ngOnInit() {
        this.passwordForm.setValidators([this.checkPasswords]);
    }

    onSubmit(): void {
        if (!validate(this.passwordForm)) {
            return;
        }
        this.loading = true;
        this.passwordChanged.emit(this.passwordForm.get('Password').value);
    }

    checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
        const validationExpr = /^(?=.*?[A-Z])(?=.*?[a-z])((?=.*?[0-9])|(?=.*?[#?!@$ %^&*-])).{8,}$/;

        let pass = group.get('Password').value;
        let confirm = group.get('ConfirmPassword').value;

        let isValid = (pass === confirm) && pass.match(validationExpr);

        return isValid ? null : { invalid: true };
    };

    onTogglePasswordVisibility(): void {
        this.showingPassword = !this.showingPassword;
    }
}