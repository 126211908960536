import { EventEmitter } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormArray, UntypedFormGroup } from "@angular/forms";

export function validate(control: AbstractControl): boolean {
    control.markAsTouched();

    let allValid = true;

    if(control instanceof UntypedFormControl) {
        (control.statusChanges as EventEmitter<string>).emit(control.status);
    } else if (control instanceof UntypedFormGroup) {
        allValid = Object.keys(control.controls).reduce((v, c) => {
            return validate(control.controls[c]) && v;
        }, true);
    } else if (control instanceof UntypedFormArray) {
        allValid = control.controls.reduce((v, c) => {
            return validate(c) && v;
        }, true);
    }

    if (control.disabled) {
        // Assume disabled controls are valid for the time being.
        // https://github.com/angular/angular/issues/11432
        return allValid;
    }

    return allValid && control.valid;
}