import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PeopleModule } from '../people/people.module';

// Layouts
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { AgentLayoutComponent } from './layouts/agent-layout/agent-layout.component';

// Components

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { EnvironmentRibbonComponent } from './components/environment-ribbon/environment-ribbon.component';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { ClientLookupDrawerComponent } from './components/client-lookup-drawer/client-lookup-drawer.component';

// Pages

import { ErrorPageComponent } from './pages/error/error.page.component';
import { NotFoundPageComponent } from './pages/not-found/not-found.page.component';
import { ErrorModalComponent } from './modals/error/error.modal.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { ErrorHeaderComponent } from './components/error-header/error-header.component';


/**
 * Base application module, contains all the components that are not specific
 * to any feature, but are required by the application as a whole.
 * */
@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        NgbModule,
        SharedModule,
        PeopleModule,
    ],
    declarations: [
        PageLoaderComponent,
        DefaultLayoutComponent,
        AgentLayoutComponent,
        HeaderComponent,
        FooterComponent,
        EnvironmentRibbonComponent,
        HeaderSearchComponent,
        ClientLookupDrawerComponent,
        ErrorModalComponent,
        ErrorHeaderComponent,
        ErrorPageComponent,
        NotFoundPageComponent
    ],
    exports: [
        PageLoaderComponent,
        DefaultLayoutComponent,
        AgentLayoutComponent,
        HeaderComponent,
        FooterComponent,
        EnvironmentRibbonComponent,
        HeaderSearchComponent,
        ClientLookupDrawerComponent,
        ErrorModalComponent,
        ErrorHeaderComponent,
        ErrorPageComponent,
        NotFoundPageComponent
    ]
})
export class CoreModule {

}