<adx-panel [iconClass]="isWarning? 'fa fa-info-circle':'fas fa-exclamation-circle'">
    <div panel-header>
        <span *ngIf="!isInformational && isError">An error has occurred</span>
        <span *ngIf="!isInformational && isWarning">Warning</span>
        <span *ngIf="isInformational && isInfo">Information</span>
        <button type="button" class="btn btn-link float-right btn-close-modal" *ngIf="isInformational || isWarning" (click)="onClose()"><i class="fas fa-times"></i></button>
    </div>
    <div panel-content>
        <div *ngIf="!apiResponseReceived" class="error-container">
            <p><strong>{{ errorMessage.UserDisplayMessage }}</strong></p>

            <form [formGroup]="errorForm">
                <ng-container *ngIf="errorMessage.IsSupportRequestEnabled">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group">
                                <textarea class="form-control" rows="4" formControlName="IssueDescription" placeholder="Please describe the steps you were taking that led to the issue and a member of our ADX support staff will respond as soon as possible."></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="showAgentSelect">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>Send As / On Behalf Of Advisor</label>
                                <select class="form-control" formControlName="OnBehalfOfAgentId">
                                    <option *ngFor="let agent of agents" [value]="agent.AgentId">{{ agent.FirstName }} {{ agent.LastName }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>CC Request To</label>
                                <input type="text" class="form-control" formControlName="CCEmail" placeholder="Recipient's Email Address" />
                                <input type="text" class="form-control" formControlName="SupportTeamType" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="isUrgent" formControlName="IsUrgent">
                                <label class="form-check-label" for="isUrgent">This request is urgent.</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 text-right">
                            <p *ngIf="!showAgentSelect">Replies will be sent to <strong>You </strong> <button class="btn btn-link" (click)="showAgentSelect = true">(Change)</button>.</p>
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div *ngFor="let message of errorMessage.ValidationMessages">
                            <strong class="request-errormsg">
                                {{ message.ErrorMessage }}
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <span class="request-id">{{ errorMessage.ErrorMessageId }}:{{ errorMessage.RequestId }}</span>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 text-right">
                        <button type="submit" class="btn btn-primary" *ngIf="errorMessage.IsSupportRequestEnabled || (!isInformational && isWarning)" [disabled]="apiBusyState" (click)="onSendSupport()">Send Support Request</button>
                        <button type="button" class="btn btn-outline-primary ml-3" (click)="onClose()">Close</button>
                        <button type="button" class="btn btn-primary" (click)="onContinue()" *ngIf="isInformational">Continue</button>
                    </div>
                </div>

                <div class="developer-block" *ngIf="developerMessage">
                    <div class="row">
                        <div class="col-sm-3 col-md-3 col-lg-3"><strong>Category</strong></div>
                        <div class="col-sm-9 col-md-9 col-lg-9">{{ developerMessage.Category }} - {{ developerMessage.Name }} <span>{{ isReviewedLabel }}</span></div>
                    </div>
                    <div class="row" *ngIf="errorMessage.DeveloperMessage">
                        <div class="col-sm-3 col-md-3 col-lg-3"><strong>Developer Message</strong></div>
                        <div class="col-sm-9 col-md-9 col-lg-9"><span>{{ errorMessage.DeveloperMessage }}</span></div>
                    </div>
                    <div class="row" *ngIf="developerMessage.CodeDescription">
                        <div class="col-sm-3 col-md-3 col-lg-3"><strong>Code Description</strong></div>
                        <div class="col-sm-9 col-md-9 col-lg-9"><span>{{ developerMessage.CodeDescription }}</span></div>
                    </div>
                    <div class="row" *ngIf="developerMessage.InternalDescription">
                        <div class="col-sm-3 col-md-3 col-lg-3"><strong>Internal Description</strong></div>
                        <div class="col-sm-9 col-md-9 col-lg-9"><span>{{ developerMessage.InternalDescription }}</span></div>
                    </div>
                </div>
            </form>

        </div>

        <div *ngIf="apiResponseReceived" class="support-response">
            <div *ngIf="!SupportTicketIdentifier" class="text-center">
                <p><strong>Uh oh! Your support request went awry. Please contact us at <a href="mailto:{{serverConfiguration.ADXSupportEmail}}">{{serverConfiguration.ADXSupportEmail}}</a>.</strong></p>
                <button class="btn btn-primary" (click)="onClose()">Close</button>
            </div>
            <div *ngIf="SupportTicketIdentifier" class="text-center">
                <p style="margin-top: 10px;">
                    We have received your request.
                    <ng-container *ngIf="isAirSupportTeam">An Internal Air Support Team member will be in touch with you shortly.</ng-container>
                    <ng-container *ngIf="!isAirSupportTeam">An ADX Support Team member will be in touch with you shortly.</ng-container>                    
                </p>
                <p style="margin-bottom: 20px;">
                    For future reference, please note your support ticket number is <strong>{{ SupportTicketIdentifier }}</strong>.
                </p>
                <button class="btn btn-primary" (click)="onClose()">Close</button>
            </div>
        </div>
    </div>
</adx-panel>
