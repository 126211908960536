import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Provides an easy way to log and retrieve errors.
 * */
@Injectable({ providedIn: 'root' })
export class ErrorLogService {
    errors: any[] = [];

    log(errorObject: any) {
        this.errors.push(errorObject);
    }

    getLatestError(): any | null {
        if (this.errors.length === 0) {
            return null;
        }

        return this.errors[this.errors.length - 1];
    }

    getLatestRequestId(): string | null {
        let latest = this.getLatestError();

        if (!latest) {
            return null;
        }

        return latest.error.RequestId as string;
    }
}
