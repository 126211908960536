import { IApiDeserializable } from '../../core/models/api-deserializable';
import { AgentCommunicationProfile } from './agent-communication-profile.model';
import {AgentBusinessProfile}  from './agent-business-profile.model';
import { PlanningFeeTypeId } from '../../service-fees/enums/planning-fee-type-id.enum';
import { ServiceFeeUseTypes } from '../../service-fees/enums/service-fee-use-types.enum';
import { ServiceFeeAmountTypes } from '../../service-fees/enums/service-fee-amount-types.enum';

/**
 * The agent model of the currently logged-in user.
 * */
export class Agent implements IApiDeserializable {
    Id: string;

    IsActive: boolean;

    Title: string;

    FirstName: string;

    MiddleName: string;

    LastName: string;

    Email: string;

    AdminNotes: string;

    AgencyId: number;

    AgentId: number;

    OfficeId: number;

    SupportedCurrencies: string[];

    CommunicationProfile: AgentCommunicationProfile;

    BusinessProfile: AgentBusinessProfile;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.CommunicationProfile = new AgentCommunicationProfile().deserialize(input.CommunicationProfile);
        this.BusinessProfile = new AgentBusinessProfile().deserialize(input.BusinessProfile);

        return this;
    }
}


export interface IHotelLoyaltyNumber {
    HotelChainOperatorCode: string;
    HotelChainOperatorName: string;
    AgentLoyaltyNumber: string;
}

/**
 * The model of the client templates.
 * */
export interface ITemplateItem {
    id: number;
    name: string;
    imageUrl?: string;
}

/**
 * The agent default planning fee model.
 * */
export interface IAgentDefaultPlanningFee{
    Amount: {
        Amount: any,
        CurrencyCode: string,
        [key: string]: any;
    };
    DefaultPlanningFeeId: number;
    FeeDescription: string; 
    FeeName: string;
    HasFee?: boolean;
    IsActive?: boolean;
    PlanningFeeTypeId:PlanningFeeTypeId;
    AmountTypeId: ServiceFeeAmountTypes;
    ServiceFeeUseTypeId: ServiceFeeUseTypes;
    [key: string]: any;
}