import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Third Party Modules
import { CookieModule } from 'ngx-cookie';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { QuillModule } from 'ngx-quill';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

// Modules (v2)
import { CoreModule } from './modules/core/core.module';
import { LoginModule } from './modules/login/login.module';

// Layouts (v2)
import { DefaultLayoutComponent } from './modules/core/layouts/default-layout/default-layout.component';

// Pages (v2)
import { LoginPageComponent } from './modules/login/pages/login/login.page.component';
import { NotFoundPageComponent } from './modules/core/pages/not-found/not-found.page.component';
import { ErrorPageComponent } from './modules/core/pages/error/error.page.component';

//
// Interceptors
//
import { AdxApiHttpInterceptor } from './modules/core/interceptors/adx-api.interceptor';

//
// Handlers
//
import { AdxErrorHandler } from './modules/core/handlers/adx-error.handler';

//
// Components
//
import { AppComponent } from './app.component';
import { AuthGuardService } from './modules/core/services/auth-guard.service';
import { AdminGuardService } from './modules/core/services/admin-guard.service';

/**
 * Main application module.
 *
 * Note that we keep the routing table in here.
 * */
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    QuillModule,
    // Modules (v2)
    CoreModule,
    LoginModule,
    DragulaModule.forRoot(),
    CookieModule.forRoot(),
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoidHJpcGFyYyIsImEiOiJjbHNleThiaWsxZmh3Mm50MG10OGphZWRrIn0.y_9kzFDMmuptVq0vIxdZvw',
    }),
    RouterModule.forRoot([
      {
        path: 'admin',
        canActivate: [AdminGuardService],
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'itineraries',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/trip-services/trip-services.module').then(m => m.TripServicesModule),
      },
      {
        path: 'itineraries',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule),
      },
      {
        path: 'insurance',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/insurance/insurance.module').then(m => m.InsuranceModule)
      },
      {
        path: 'agent',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/agent/agent.module').then(m => m.AgentModule)
      },
      {
        path: 'air',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/air/air.module').then(m => m.AirModule)
      },
      {
        path: 'hotel',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/hotel/hotel.module').then(m => m.HotelModule)
      },
      {
        path: 'activity',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/activity/activity.module').then(m => m.ActivityModule)
      },
      {
        path: 'external-service',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/external-services/external-services.module').then(m => m.ExternalServicesModule)
      },
      {
        path: 'providers',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/providers/providers.module').then(m => m.ProvidersModule)
      },
      {
        path: 'resources',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/te-network/resources.module').then(m => m.ResourcesModule)
      },
      {
        path: 'people',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'cruise',
        loadChildren: () => import('./modules/cruise/cruise.module').then(m => m.CruiseModule)
      },
      {
        path: 'search',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
      },
      {
        path: 'planning-fee',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/planning-fee/planning-fee.module').then(m => m.PlanningFeeModule),
      },
      {
        path: 'transport',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/transport/transport.module').then(m => m.TransportModule),
      },
      {
        path: 'login',
        component: DefaultLayoutComponent,
        children: [
          {
            path: '',
            component: LoginPageComponent
          }
        ]
      },
      {
        path: 'not-found',
        component: DefaultLayoutComponent,
        children: [
          {
            path: '',
            component: NotFoundPageComponent
          }
        ]
      },
      {
        path: '',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/agent/agent.module').then(m => m.AgentModule)
      },
      {
        path: '**',
        component: NotFoundPageComponent
      }
    ], { scrollPositionRestoration: 'enabled' })
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdxApiHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AdxErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
