import { Component } from '@angular/core';

/**
 * Displays the ADX Page Loader, usually only displayed when we first start up the application.
 */
@Component({
    selector: 'adx-page-loader',
    templateUrl: './page-loader.component.html',
    styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent {

}
