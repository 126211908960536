import { Injectable, EventEmitter } from '@angular/core';
import { HttpRequest, HttpHandler } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from '../modals/login/login.modal.component';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Handles login errors that occur at the HTTP API layer.
 * */
@Injectable({ providedIn: 'root' })
export class LoginErrorService {
    constructor(private modalService: NgbModal) {

    }

    login$: Subject<void>;

    isShowingModal: boolean = false;

    /**
     * Enqueues a failed request and returns an observable that will be
     * called when the user logs in.
     */
    enqueue(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Display the login modal upon the first request failing.
        if (!this.isShowingModal) {
            this.login$ = new Subject<void>();
            this.showLoginModal();
        }

        return this.login$.pipe(
            switchMap(_ => {
                return next.handle(req);
            })
        );
    }

    showLoginModal(): void {
        this.isShowingModal = true;

        // Show modal and wait for the login successful event, then go ahead and retry the failed requests.
        const modalRef = this.modalService.open(LoginModalComponent, {
            backdrop: 'static'
        });

        const login$ = (modalRef.componentInstance.login as EventEmitter<any>).subscribe(_ => {
            login$.unsubscribe();
            modalRef.dismiss();

            this.login$.next();
            this.login$.complete();

            this.isShowingModal = false;
        });
    }

    get hasQueueItems(): boolean {
        return this.isShowingModal;
    }
}
