import {ErrorHandler, Injectable, NgZone, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {SupportApiService} from '../services/support-api.service';
import {Router} from '@angular/router';

declare interface RejectedError {
  promise: Promise<any>;
  rejection: {
    error: {
      status: number;
    };
  };
}

declare const InstallTrigger: any;
declare const document: {
  documentMode: any,
};
declare const window: {
  opera: any,
  opr: any,
  chrome: any,
  StyleMedia: any,
  HTMLElement: any,
  CSS: any
};
declare const opr: {
  addons: any;
};
declare const safari: {
  pushNotification: any
};

/**
 * Provides a centralized place to capture front-end errors.
 * */
@Injectable()
export class AdxErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone, private supportApi: SupportApiService, private injector: Injector) {

  }

  handleError(error: Error): void {
    if (!error) {
      return;
    }

    const rejectedError:RejectedError = (error as any) as RejectedError;
    if (rejectedError.rejection?.error) {
      // huh, it was actually an uncaught promise
      const status = rejectedError.rejection.error.status;
      if (rejectedError.rejection.error instanceof HttpErrorResponse) {
       // Let AdxHttpInterceptor handle HTTP-related errors
        return;
      }
    }

    // Let AdxHttpInterceptor handle HTTP-related errors.
    if (error instanceof HttpErrorResponse || (<any>error).error instanceof HttpErrorResponse) {
      return;
    }

    const guid = this.createGuid();
    const errorDto = {
      Guid: guid,
      UserAgent: this.getPotentialUserAgent(),
      StackTrace: error.stack
    };

    console.log(errorDto);

    localStorage.setItem('js_error', JSON.stringify(errorDto));

    this.zone.run(() => {
      const router = this.injector.get(Router);
      location.href = `/v1/js-error?returnUrl=${router.url}&guid=${guid}`;
    });

    throw error;
  }

  createGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getPotentialUserAgent(): string {
    //ignore jslint - Lifted from https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769.

    var isOpera,
      isFirefox,
      isSafari,
      isIE,
      isEdge,
      isChrome,
      isBlink,
      detectedBrowserTypes;

    detectedBrowserTypes = [];

    // Opera 8.0+
    isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0; //ignore jslint (window.opr)

    if (isOpera) {
      detectedBrowserTypes.push('Opera');
    }

    // Firefox 1.0+
    isFirefox = typeof InstallTrigger !== 'undefined'; //ignore jslint

    if (isFirefox) {
      detectedBrowserTypes.push('Firefox');
    }

    // Safari 3.0+ "[object HTMLElementConstructor]"
    isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));//ignore jslint

    if (isSafari) {
      detectedBrowserTypes.push('Safari');
    }

    // Internet Explorer 6-11
    isIE = /*@cc_on!@*/false || !!document.documentMode;//ignore jslint

    if (isIE) {
      detectedBrowserTypes.push('IE 6-11');
    }

    // Edge 20+
    isEdge = !isIE && !!window.StyleMedia;

    if (isEdge) {
      detectedBrowserTypes.push('IE Edge');
    }

    // Chrome 1+
    isChrome = !!window.chrome && !!window.chrome.webstore;

    if (isChrome) {
      detectedBrowserTypes.push('Chrome');
    }

    // Blink engine detection
    isBlink = (isChrome || isOpera) && !!window.CSS;

    if (isBlink) {
      detectedBrowserTypes.push('Chrome (Blink)');
    }

    if (detectedBrowserTypes.length === 0) {
      return navigator.userAgent;
    }

    return detectedBrowserTypes.join(', ');
  }
}
