/**
 * Agent information stored in the cookie.
 * @readonly
 * */

import { UserRoles } from '../../shared/enums/user-roles.enum';
import { AffiliationTypes } from '../enums/affiliation-types.enum';

export class AgentCookie {
    AgentId: number;

    UserId: number;

    FirstName: string;

    LastName: string;

    Email: string;

    Roles: string[];

    Preferences: any;

    SupportedCurrencies: string[];

    OfficeName: string;

    OfficeCanSellTravelServices: string[];

    AffiliateType?: AffiliationTypes;

    UserAD: string;

    AgencyName: string;

    AgentType: string;

    get isAdminUser(): boolean {
        return this.hasRole(UserRoles.Admin);
    }

    get isPilotUser(): boolean {
        return this.hasRole('PilotUser');
    }

    get isPilotUser2(): boolean {
        return this.hasRole('PilotUser2');
    }

    get isPilotUser3(): boolean {
        return this.hasRole('PilotUser3');
    }

    get isPilotUser4(): boolean {
        return this.hasRole('PilotUser4');
    }

    get isPilotUser5(): boolean {
        return this.hasRole('PilotUser5');
    }

    get isPilotUser6(): boolean {
      return this.hasRole('PilotUser6');
    }

    get isPilotUser7(): boolean {
      return this.hasRole('PilotUser7');
    }

    get isPilotUser8(): boolean {
      return this.hasRole('PilotUser8');
    }

    get isPilotUser9(): boolean {
      return this.hasRole('PilotUser9');
    }

    get isPilotUser10(): boolean {
      return this.hasRole('PilotUser10');
    }

    get isKtAgent(): boolean {
        return this.OfficeName === 'Kensington Tours Air Department';
    }

    get isKtHotelsOnly(): boolean {
        return this.hasRole('KensingtonHotelsOnly');
    }

    get isEnsembleAgent(): boolean {
        return this.AffiliateType === AffiliationTypes.EnsembleAffiliate;
    }
    get isTraveledgeAgent(): boolean{
        return this.AffiliateType === AffiliationTypes.TravelEdgeAffiliate;
    }

    get isSsoBypassed(): boolean {
        return this.hasRole('BypassSso');
    }

    get usesExternalLogin(): boolean {
        return this.isEnsembleAgent && !this.isSsoBypassed;
    }

    get isPlus5User(): boolean {
        return this.hasRole('Plus5');
    }

    get isPlusMarkupUser(): boolean {
      return this.hasRole('PlusMarkup');
    }

    public get isTransportUser(): boolean {
        return this.hasRole(UserRoles.ADXTransfer);
    }

    public get isBundleBuilder(): boolean {
        return this.hasRole(UserRoles.BuildABundle);
    }

    public get isCashPaymentUser(): boolean {
        return this.hasRole('ADXCashPayment');
    }
    
    public get isBundleMerchantOfRecordUser(): boolean {
        return this.hasRole('BundleMerchantOfRecord');
    }
    
    public get isCorporateCardUser(): boolean {
        return this.hasRole('ADXKTAirCreditCard');
    }

    IsComissionVisible(): boolean {
        return this.Preferences.DisplayCommission;
    }

    IsAdminVisible(): boolean {
        if (this.hasRole('Administrator')) {
            return this.Preferences.DisplayAdmin === true || this.Preferences.DisplayAdmin === undefined || this.Preferences.DisplayAdmin === null || !!this.Preferences.DisplayAdmin;
        }
        return false;
    }

    hasRole(role: string | UserRoles): boolean {
        return this.Roles.indexOf(role) !== -1;
    }

    get isAffiliateAgent(): boolean {
      return this.AffiliateType === AffiliationTypes.EnsembleAffiliate ||
        this.AffiliateType === AffiliationTypes.TravelEdgeAffiliate;
    }

    get getAgentName(): string{
      return this.FirstName + ' ' + this.LastName;
    }
}
